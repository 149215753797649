import { useState } from 'react';
import { useTenantApi } from '@/account/hook/useTenantApi';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { UiStack, UiText } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useQuery } from '@tanstack/react-query';
import { type IEmailTableRes } from '@/api/registration/automatedEmail';
import AutomatedTable from './EmailCampaignsTable';
import CreateScheduledEmail from './CreateScheduledEmail';
import { useDisclosure } from '@chakra-ui/react';
import { campaignsEmailKey, getCampaignsEmail } from '@/api/registration/campaignsEmail';

export interface AutomatedOptions {
  value: keyof Omit<IEmailTableRes, 'newEmailTypes'>
  label: string
}

const AutomatedList = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { eventId } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const [selectedEmailId, setSelectedEmailId] = useState<number | string | null>(null);
  const { onOpen: onOpenDelete } = useDisclosure();

  const campaignEmailQuery = useQuery({
    queryKey: [campaignsEmailKey, { eventId }],
    queryFn: async () => {
      return await getCampaignsEmail(createTenantAdminApiRequest)({ eventId });
    },
    enabled: !isApiPreparing
  });

  return (
    <QueryContainer query={campaignEmailQuery}>
      {(campaignEmailTableData) => {
        return (
          <>
            <CreateScheduledEmail isOpen={isOpen}
              campaignEmailTableData={campaignEmailTableData.items}
              onToggle={onToggle}
              emailId={selectedEmailId}
              onClose={() => {
                setSelectedEmailId(null);
                onToggle();
              }} />
            <UiStack
              borderRadius={'lg'}
              spacing={4}
            >
              <AutomatedTable
                data={campaignEmailTableData.items}
                onDelete={(id) => {
                  setSelectedEmailId(id);
                  onOpenDelete();
                }}
                onCreateCampaignEmail={(id) => {
                  if (!id) return;
                  setSelectedEmailId(id);
                  onToggle();
                }} />
            </UiStack>
          </>
        );
      }}
    </QueryContainer>
  );
};

export default AutomatedList;
