import { useRef, type FC } from 'react';
import { UiButton, UiMenu, UiMenuButton, UiMenuItem, UiMenuList, UiStack, type UiStackProps, uiStyles, UiTable, UiTableContainer, UiTd, UiText, UiTh, UiThead, UiTr } from '@/lib/ui';
import { Tbody } from '@chakra-ui/react';
import { type IScheduledEmailRes } from '@/api/registration/scheduledEmail';
import { useResizeObserver } from 'usehooks-ts';

const thStyles = {
  px: 8,
  py: 6
};
const tdStyles = {
  px: 8,
  py: 6,
};

interface IScheduledEmailTableProps {
  data: IScheduledEmailRes[]
  onView?: (id: string | number) => void
  onCancel?: (id: string | number) => void
}

const ScheduledEmailTable: FC<IScheduledEmailTableProps> = ({ data = [], onView = () => { }, onCancel = () => { } }) => {
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const { width = 0 } = useResizeObserver({
    ref: tableContainerRef,
  });

  const tableWrapperOverflow: UiStackProps = {
    overflowX: 'scroll',
    maxW: `${width}px`,
  };
  return (
    <UiTableContainer ref={tableContainerRef} shadow={uiStyles.cardShadow} borderRadius={uiStyles.borderRadius} maxHeight={500} overflowY='scroll'>
      <UiStack {...tableWrapperOverflow}>
        <UiTable variant='simple' bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
          <UiThead>
            <UiTr>
              <UiTh {...thStyles} width='20%'><UiText fontWeight={600} variant={'body2'} textTransform="none" >ID</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Subject line</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Type</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Event</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Date Time Scheduled</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Action</UiText></UiTh>
            </UiTr>
          </UiThead>
          <Tbody>
            {
              data.map(({ id, subjectLine, event, type, dateTimeScheduled }) => {
                return (
                  <UiTr key={id}>
                    <UiTd {...tdStyles} wordBreak='break-word'>{id}</UiTd>
                    <UiTd {...tdStyles}>{subjectLine}</UiTd>
                    <UiTd {...tdStyles}>{type}</UiTd>
                    <UiTd {...tdStyles}>{event}</UiTd>
                    <UiTd {...tdStyles}>{dateTimeScheduled}</UiTd>
                    <UiTd {...tdStyles}>
                      <UiMenu>
                        <UiMenuButton as={UiButton} size='sm'>
                          Actions
                        </UiMenuButton>
                        <UiMenuList>
                          <UiMenuItem onClick={() => { return onView(id); }}>View</UiMenuItem>
                          <UiMenuItem onClick={() => { return onCancel(id); }}>Cancel</UiMenuItem>
                        </UiMenuList>
                      </UiMenu>
                    </UiTd>
                  </UiTr>
                );
              })
            }
          </Tbody>
        </UiTable>
      </UiStack>
    </UiTableContainer>
  );
};

export default ScheduledEmailTable;
