import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import AutomatedList from './ScheduledEmailList';

const ScheduledEmailCampaigns: FC = () => {
  const { eventId } = useRegisterRoute();

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
        MainTransition={null}
      >
        <ManageEventLayoutBody
          steps={(
            <ManageEventSteps
              eventId={eventId}
              activeStep={'scheduledEmail'}
            />
          )}
          message={'Group your input fields.'}
        >
          <UiStack
            spacing={4}
            flexGrow={1}
            p={8}
            justifyContent={'flex-start'}
          >
            <AutomatedList />
          </UiStack>
          <UiHStack p={8} justifyContent={'space-between'}>
            <UiStack flexGrow={1} />
          </UiHStack>
        </ManageEventLayoutBody>
      </BaseLayout>
    </>
  );
};

export default ScheduledEmailCampaigns;
