import { Formik, type FormikProps } from 'formik';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useGlobalToast } from '@/lib/util';
import { useMemo, useRef, useState } from 'react';
import { UiStack } from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { createEmailCampaign, type ICreateEmailCampaignParams, loadUserOptions, type ICampaignsEmailRes, campaignsEmailKey, getEmailCampaignForm, type IEmailCampaignFormRes } from '@/api/registration/campaignsEmail';
import { scheduledEmailKey } from '@/api/registration/scheduledEmail';
import { type ApiResponseSingle } from '@/api/tenantClient';

interface CampaignEmailFormProps {
  isOpen: boolean
  emailId: string | number | null
  onToggle: () => void
  onClose: () => void
  campaignEmailTableData: ICampaignsEmailRes[]
}

interface FormData {
  emailId: number
  delegateTypeIds: number[]
  attendeeCategoryIds: number[]
  userIds: number[]
  exhibitorTypeIds?: number[]
  sponsorTierIds?: number[]
  speakerTypeIds?: number[]
  pushAt: string
}

const inititalValues = {
  emailId: null,
  delegateTypeIds: [],
  attendeeCategoryIds: [],
  userIds: [],
  exhibitorTypeIds: [],
  sponsorTierIds: [],
  speakerTypeIds: [],
  pushAt: ''
};

const getCampainOptions = (emailCampaignFormData: ApiResponseSingle<IEmailCampaignFormRes>): Partial<Record<keyof IEmailCampaignFormRes, Option[]>> => {
  const optionKeys = Object.keys(emailCampaignFormData.item) as Array<keyof IEmailCampaignFormRes>;
  return optionKeys.reduce((acc: Partial<Record<keyof IEmailCampaignFormRes, Option[]>>, key) => {
    if (emailCampaignFormData?.item[key as keyof IEmailCampaignFormRes]?.length === 0) return acc;
    const options = emailCampaignFormData?.item[key].map((item) => {
      return {
        value: item[1],
        label: item[0]
      };
    });
    return {
      ...acc,
      [key]: options
    };
  }, {});
};
const CampaignEmailForm = ({ isOpen, onClose, campaignEmailTableData, emailId }: CampaignEmailFormProps) => {
  const formRef = useRef<FormikProps<FormData> | null>(null);
  const [pagination] = useState({ page: 1, pageSize: 10 });
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState<string[]>([]);
  const { eventId } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { showToast } = useGlobalToast();

  const { data: emailCampaignFormData } = useQuery({
    queryKey: [campaignsEmailKey, 'form', { eventId }],
    queryFn: async () => {
      return await getEmailCampaignForm(createTenantAdminApiRequest)({ eventId });
    },
  });
  const { emailTemplates, attendeeCategories, delegateTypes, exhibitorTypes, speakerTypes, sponsorTiers } = emailCampaignFormData ? getCampainOptions(emailCampaignFormData) : {
    emailTemplates: [],
    attendeeCategories: [],
    delegateTypes: [],
    exhibitorTypes: [],
    speakerTypes: [],
    sponsorTiers: []
  };

  const defaultNotificationOptions = (emailTemplates ?? []).find((option) => { return option.value === emailId; });

  const { data: userOptionsData } = useQuery({
    queryKey: ['userOptions', { page: pagination.page }],
    queryFn: async () => {
      return await loadUserOptions(createTenantAdminApiRequest)({ page: pagination.page });
    },
    enabled: !isApiPreparing
  });
  const userOptions = useMemo(() => {
    if ((userOptionsData?.items ?? []).length === 0) return [];
    return (userOptionsData?.items ?? []).map(({ id, text }) => {
      return {
        value: id,
        label: text
      };
    });
  }, [userOptionsData]);

  const { mutate: createEmailCampaignMutate, isLoading: isCreateEmailCampaignLoading } = useMutation({
    mutationFn: async (params: Omit<ICreateEmailCampaignParams, 'eventId'>) => {
      return await createEmailCampaign(createTenantAdminApiRequest)({ eventId, ...params });
    },
    onSuccess: (res) => {
      if ((res.errors ?? []).length > 0) {
        setErrors(res?.errors ?? []);
        return;
      }
      void queryClient.invalidateQueries({
        queryKey: [campaignsEmailKey, { eventId }]
      });
      void queryClient.invalidateQueries({
        queryKey: [scheduledEmailKey, { eventId }]
      });
      showToast.success('Creating email campaign...');
      onClose();
      if (formRef?.current) {
        formRef?.current?.resetForm();
      }
    },
  });

  const handleSubmit = async (values: FormData) => {
    createEmailCampaignMutate(values as Omit<ICreateEmailCampaignParams, 'eventId'>);
  };

  return (
    <Formik<FormData>
      initialValues={{ ...inititalValues, emailId: Number(defaultNotificationOptions?.value) ?? 0 }}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {
        () => {
          return <BaseFormDrawer
            title={'Email campaign'}
            isOpen={isOpen}
            isLoading={isCreateEmailCampaignLoading}
            onClose={() => {
              onClose();
              setErrors([]);
              formRef?.current?.resetForm();
            }}
            size={'lg'}
          >
            <BaseFormFieldGroup>
              <BaseFormSelectField
                isRequired={false}
                defaultValue={defaultNotificationOptions}
                name={'emailId'}
                label={'Notification'}
                options={emailTemplates ?? []}
                helperText='Select the notification you want to send.'
              />
              <BaseFormSelectField
                isRequired={false}
                isMultiple
                name={'delegateTypeIds'}
                label={'Choose Attendee Groups'}
                helperText='Select all Attendee Groups this relates to'
                options={delegateTypes ?? []}
              />
              <BaseFormSelectField
                isRequired={false}
                isMultiple
                name={'attendeeCategoryIds'}
                label={'Choose Category'}
                helperText='Select all Attendee Categories this relates to'
                options={attendeeCategories ?? []} />

              <BaseFormSelectField
                isRequired={false}
                isMultiple
                name={'userIds'}
                label={'Choose Users'}
                helperText='Select all sponsor types this relates to'
                options={userOptions} />

              <BaseFormSelectField
                isRequired={false}
                isMultiple
                name={'exhibitorTypeIds'}
                label={'Choose Exhibitor Type'}
                options={exhibitorTypes ?? []} />

              <BaseFormSelectField
                isRequired={false}
                isMultiple
                name={'sponsorTierIds'}
                label={'Choose Sponsor Types'}
                options={sponsorTiers ?? []} />

              <BaseFormSelectField
                isRequired={false}
                isMultiple
                name={'speakerTypeIds'}
                label={'Choose Speaker Types'}
                options={speakerTypes ?? []} />

              <BaseFormInputField
                name={'pushAt'}
                label={'Date and Time for scheduled notification'}
                type={'datetime-local'}
                helperText="The provided date should be in the event's time zone."
              />
              {errors.length > 0 && (
                <UiStack spacing={4} flexGrow={1} py={4}>
                  {errors.map((error, index) => {
                    return (
                      <BaseMessageBarError key={index}>
                        {error}
                      </BaseMessageBarError>
                    );
                  })}
                </UiStack>
              )}
            </BaseFormFieldGroup>
          </BaseFormDrawer>;
        }
      }
    </Formik>
  );
};

export default CampaignEmailForm;
