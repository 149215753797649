import { useState, type FC } from 'react';
import { useTenantApi } from '@/account/hook/useTenantApi';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { UiHStack, UiStack, UiText } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { automatedEmailListKey, deleteAutomatedEmail, type IEmailTableRes, loadEmailTable } from '@/api/registration/automatedEmail';
import NewFormRow from './NewFormRow';
import { type Option } from '@/base/Form/SelectField';
import EditAutomatedEmailForm from './EditAutomatedEmailForm';
import { useDisclosure } from '@chakra-ui/react';
import { useGlobalToast } from '@/lib/util';
import DeletionConfirmation from '../AttendeeCategories/DeletionConfirmation';
import AutomatedCategoryList from '@/registration/buildEvent/AutomatedEmail/AutomatedCategoryList';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface AutomatedOptions {
  value: keyof Omit<IEmailTableRes, 'newEmailTypes'>
  label: string
}

interface AutomatedListProps {
  categories?: AutomatedOptions[]
}

const AutomatedList: FC<AutomatedListProps> = ({ categories = [] }) => {
  const queryClient = useQueryClient();
  const { showToast } = useGlobalToast();
  const { isOpen, onToggle } = useDisclosure();
  const { eventId, tenant } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const [selectedEmailId, setSelectedEmailId] = useState<number | string | null>(null);
  const { isOpen: isDeleteOpen, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

  const automatedEmailsQuery = useQuery({
    queryKey: [automatedEmailListKey, { eventId }],
    queryFn: async () => {
      return await loadEmailTable(createTenantAdminApiRequest)({ eventId });
    },
    enabled: !isApiPreparing
  });

  const { mutate: deleteAutomatedEmailMutate, isLoading: isDeleteAutomatedLoading } = useMutation({
    mutationFn: async () => {
      if (!selectedEmailId) return;
      return await deleteAutomatedEmail(createTenantAdminApiRequest)({ emailId: selectedEmailId });
    },
    onSuccess: (res) => {
      if ((res?.errors ?? []).length > 0) {
        showToast.error(res?.errors?.[0] ?? 'Failed to delete automated email');
        return;
      }
      void queryClient.invalidateQueries({
        queryKey: [automatedEmailListKey, { eventId }]
      });
      onCloseDelete();
    }
  });

  return (
    <QueryContainer query={automatedEmailsQuery}>
      {(automatedEmails) => {
        const { newEmailTypes, ...emailsTable } = automatedEmails.item;
        const notificationOptions: Option[] = newEmailTypes.map((type) => { return { label: type, value: type }; });
        return (
          <>
            <UiHStack justifyContent={'space-between'}>
              <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
                <NewFormRow notificationOptions={notificationOptions} />
              </BaseAclContainer>
            </UiHStack>
            <EditAutomatedEmailForm isOpen={isOpen}
              onToggle={onToggle}
              emailId={selectedEmailId}
              onClose={() => {
                setSelectedEmailId(null);
                onToggle();
              }}
            />
            <UiStack
              spacing={8}
              // enableDivider={true}
            >
              {categories.length > 0 && categories.map((category) => {
                return (
                  <UiStack
                    key={category.value}
                    spacing={4}
                  >
                    <UiText>{category.label}</UiText>
                    <AutomatedCategoryList
                      data={emailsTable[category.value]}
                      onDelete={(id) => {
                        setSelectedEmailId(id);
                        onOpenDelete();
                      }}
                      onEdit={(id) => {
                        if (!id) return;
                        setSelectedEmailId(id);
                        onToggle();
                      }}
                    />
                  </UiStack>
                );
              })}
            </UiStack>
            <DeletionConfirmation
              isLoading={isDeleteAutomatedLoading}
              isOpen={isDeleteOpen || isDeleteAutomatedLoading}
              onClose={onCloseDelete}
              onConfirm={() => { deleteAutomatedEmailMutate(); }}
              name={'automated email'}
            />
          </>
        );
      }}
    </QueryContainer>
  );
};

export default AutomatedList;
