import { type FC } from 'react';
import {
  UiIconPlusCircle
} from '@/lib/ui';
import BaseButtonIconText from '@/base/Button/IconText';
import CreateAutomatedEmailForm from './CreateAutomatedEmailForm';
import { useDisclosure } from '@chakra-ui/react';
import { type Option } from '@/base/Form/SelectField';
import { useEventQuery } from '@/registration/hook/useEventQuery';

export interface NewFormProps {
  notificationOptions: Option[]
}

const NewFormRow: FC<NewFormProps> = ({ notificationOptions = [] }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <BaseButtonIconText onClick={onToggle} Icon={UiIconPlusCircle}>Add new automated email</BaseButtonIconText>
      <CreateAutomatedEmailForm isOpen={isOpen} onToggle={onToggle} notificationOptions={notificationOptions} />
    </>
  );
};

export default NewFormRow;
