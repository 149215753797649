import { useRef, type FC } from 'react';
import {
  UiButton, UiGrid,
  UiHStack, UiIconLayout, UiIconPencilSimple, UiIconX,
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiStack,
  type UiStackProps,
  uiStyles,
  UiTable,
  UiTableContainer,
  UiTd,
  UiText,
  UiTh,
  UiThead,
  UiTr,
} from '@/lib/ui';
import { Tbody } from '@chakra-ui/react';
import { type IScheduledEmailRes } from '@/api/registration/scheduledEmail';
import { useResizeObserver } from 'usehooks-ts';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';
import BaseBlockList from '@/base/BlockList';

const thStyles = {
  px: 8,
  py: 6
};
const tdStyles = {
  px: 8,
  py: 6,
};

interface IScheduledEmailListProps {
  data: IScheduledEmailRes[]
  onView?: (id: string | number) => void
  onCancel?: (id: string | number) => void
}

const ScheduledEmailList: FC<IScheduledEmailListProps> = ({ data = [], onView = () => { }, onCancel = () => { } }) => {
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const { width = 0 } = useResizeObserver({
    ref: tableContainerRef,
  });

  const tableWrapperOverflow: UiStackProps = {
    overflowX: 'scroll',
    maxW: `${width}px`,
  };

  return (
    <BaseBlockList
      bgColor={'#fff'}
      enableDivider={true}
    >
      {data.map(({ id, subjectLine, event, type, dateTimeScheduled }) => {
        return (
          <UiHStack
            p={8}
            py={4}
            justifyContent={'space-between'}
          >
            <UiGrid
              templateColumns={'2fr 2fr 2fr 1fr'}
              gap={8}
              flexGrow={1}
            >
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Subject line
                </UiText>
                <UiText variant={'body2'}>
                  {subjectLine}
                </UiText>
              </UiStack>
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Type
                </UiText>
                <UiText variant={'body2'}>
                  {type}
                </UiText>
              </UiStack>
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Event
                </UiText>
                <UiText variant={'body2'}>
                  {event}
                </UiText>
              </UiStack>
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Date time scheduled
                </UiText>
                <UiText variant={'body2'}>
                  {dateTimeScheduled}
                </UiText>
              </UiStack>
            </UiGrid>
            <UiHStack spacing={4}>
              <BaseButtonPlainIcon
                label={"Edit"}
                Icon={UiIconPencilSimple}
                color={"primary.500"}
                onClick={() => { return onView(id); }}
                iconSize={'xl'}
              />
              <BaseButtonPlainIcon
                label={"Delete"}
                Icon={UiIconX}
                color={"red.500"}
                onClick={() => { return onCancel(id); }}
                iconSize={'xl'}
              />
            </UiHStack>
          </UiHStack>
        );
      })}
    </BaseBlockList>
  );

  // return (
  //   <UiTableContainer ref={tableContainerRef} shadow={uiStyles.cardShadow} borderRadius={uiStyles.borderRadius} maxHeight={500} overflowY='scroll'>
  //     <UiStack {...tableWrapperOverflow}>
  //       <UiTable variant='simple' bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
  //         <UiThead>
  //           <UiTr>
  //             <UiTh {...thStyles} width='20%'><UiText fontWeight={600} variant={'body2'} textTransform="none" >ID</UiText></UiTh>
  //             <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Subject line</UiText></UiTh>
  //             <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Type</UiText></UiTh>
  //             <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Event</UiText></UiTh>
  //             <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Date Time Scheduled</UiText></UiTh>
  //             <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Action</UiText></UiTh>
  //           </UiTr>
  //         </UiThead>
  //         <Tbody>
  //           {
  //             data.map(({ id, subjectLine, event, type, dateTimeScheduled }) => {
  //               return (
  //                 <UiTr key={id}>
  //                   <UiTd {...tdStyles} wordBreak='break-word'>{id}</UiTd>
  //                   <UiTd {...tdStyles}>{subjectLine}</UiTd>
  //                   <UiTd {...tdStyles}>{type}</UiTd>
  //                   <UiTd {...tdStyles}>{event}</UiTd>
  //                   <UiTd {...tdStyles}>{dateTimeScheduled}</UiTd>
  //                   <UiTd {...tdStyles}>
  //                     <UiMenu>
  //                       <UiMenuButton as={UiButton} size='sm'>
  //                         Actions
  //                       </UiMenuButton>
  //                       <UiMenuList>
  //                         <UiMenuItem onClick={() => { return onView(id); }}>View</UiMenuItem>
  //                         <UiMenuItem onClick={() => { return onCancel(id); }}>Cancel</UiMenuItem>
  //                       </UiMenuList>
  //                     </UiMenu>
  //                   </UiTd>
  //                 </UiTr>
  //               );
  //             })
  //           }
  //         </Tbody>
  //       </UiTable>
  //     </UiStack>
  //   </UiTableContainer>
  // );
};

export default ScheduledEmailList;
