import { type ApiResponse, type ApiRequest, properCallApi } from '../tenantClient';

export const scheduledEmailKey = 'scheduledEmail';

export interface IScheduledEmailRes {
  id: number
  subjectLine: string
  type: string
  event: string
  dateTimeScheduled: string
}

export const loadScheduledEmails = (createTenantAdminRequest: () => ApiRequest) => {
  const request = createTenantAdminRequest();
  return async (params: {
    eventId: string | number
  }): Promise<ApiResponse<IScheduledEmailRes>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/email_campaign_schedules';
    request.endpoint.query = {
      event_id: params.eventId
    };
    return await properCallApi<ApiResponse<IScheduledEmailRes>>(request);
  };
};

interface ICreateScheduledEmailParams {
  eventId: string | number
  emailId: string | number
}

export const cancelScheduledEmail = (createTenantAdminRequest: () => ApiRequest) => {
  const request = createTenantAdminRequest();
  return async (params: ICreateScheduledEmailParams): Promise<ApiResponse<any>> => {
    request.method = 'DELETE';
    request.endpoint.path = `/api/v1/admin/email_campaign_schedules/${params.emailId}`;
    request.endpoint.query = {
      event_id: params.eventId
    };
    return await properCallApi<ApiResponse<any>>(request);
  };
};
