import { Formik, type FormikProps } from 'formik';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import CheckboxListField from '@/base/Form/CheckboxListField';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ReactTrix, { type ITrixEditor } from './ReactTrix';
import BaseFormSelectField from '@/base/Form/SelectField';
import { automatedEmailCategoriesKey, editAutomatedEmail, getAutomatedEmail } from '@/api/registration/automatedEmail';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useGlobalToast } from '@/lib/util';
import { useEffect, useMemo, useRef, useState } from 'react';
import { UiStack } from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';
import * as Yup from 'yup';
import { useEventQuery } from '@/registration/hook/useEventQuery';

interface AutomatedEmailFormProps {
  isOpen: boolean
  emailId: string | number | null
  onToggle: () => void
  onClose: () => void
}

interface FormData {
  subject: string
  content?: string
  emailFromName: string
  activeEmailTemplate: number
  header: string
  footer?: string
  status: boolean | string
  includeInvoice: boolean
}

interface IEmailTemplate {
  subject: string
  content?: string
  emailType: string
  emailFromName: string
  header: string
  footer?: string
  status: boolean
  includeInvoice: boolean
  activeEmailTemplate: number
}

const automatedEmailSchema = Yup.object().shape({
  subject: Yup.string().required('Subject line is required'),
});

const emailStatusOptions = [{
  label: 'Enabled',
  value: 'true'
}, {
  label: 'Disabled',
  value: 'false'
}];

const initialFormValues = {
  subject: '',
  content: '',
  emailFromName: '',
  header: '',
  footer: '',
  status: 'false',
  includeInvoice: false,
  activeEmailTemplate: 0
};
const EditAutomatedEmailForm = ({ isOpen, onToggle, emailId, onClose }: AutomatedEmailFormProps) => {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState<string[]>([]);
  const formRef = useRef<FormikProps<FormData>>(null);
  const contentRef = useRef<ITrixEditor>(null);
  const { eventId } = useRegisterRoute();
  const { data: eventData } = useEventQuery(eventId);
  const isEnabledFeatureFlag = eventData?.enabledFlagEmailBuilder ?? false;
  const { showToast } = useGlobalToast();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { data, isSuccess, isLoading: isEmailLoading } = useQuery({
    queryKey: [automatedEmailCategoriesKey, { eventId, emailId }],
    queryFn: async () => {
      if (!eventId || !emailId) return;
      return await getAutomatedEmail(createTenantAdminApiRequest)({ eventId, emailId });
    },
    enabled: !!emailId && !!eventId
  });
  const emailTemplatesOptions = useMemo(() => {
    return (data?.item?.emailTemplates ?? []).map((template) => {
      return { label: template.name, value: template.id };
    });
  }, [data]);

  const { mutate: editAutomatedEmailMutate, isLoading } = useMutation({
    mutationFn: async (params: IEmailTemplate) => {
      if (!emailId) return;
      return await editAutomatedEmail(createTenantAdminApiRequest)({ ...params, emailId, eventId });
    },
    onSuccess: (res) => {
      if ((res?.errors?.length ?? 0) > 0) {
        setErrors([...res?.errors ?? []]);
        return;
      }
      onToggle();
      showToast.success('Automated email updated successfully');
      void queryClient.invalidateQueries({
        queryKey: [automatedEmailCategoriesKey, { eventId, emailId }],
      });
    }
  });
  const handleSubmit = (values: FormData) => {
    if (!eventId || !emailId || !data?.item) return;
    editAutomatedEmailMutate({
      activeEmailTemplate: values.activeEmailTemplate || data.item.activeEmailTemplate,
      emailType: data.item.emailType,
      emailFromName: values.emailFromName,
      footer: values.footer,
      header: values.header,
      includeInvoice: values.includeInvoice,
      status: values.status === 'true',
      subject: values.subject,
      content: values.content
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.item) {
        data.item.status = data.item.status ? 'true' : 'false';
        void formRef.current?.setValues(data.item);
        contentRef?.current?.editor.loadHTML(data.item.content);
      }
    }
  }, [isSuccess, isEmailLoading, data, isOpen]);
  return (
    <Formik<FormData>
      initialValues={data?.item ?? initialFormValues}
      innerRef={formRef}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={automatedEmailSchema}
    >
      {
        (form) => {
          return (
            <BaseFormDrawer
              isLoading={isLoading}
              title={'Edit automated email'}
              isOpen={isOpen}
              onClose={() => {
                form.resetForm();
                onClose();
              }}
              size={'lg'}
            >
              <BaseFormFieldGroup>
                <BaseFormInputField
                  name={'subject'}
                  label={'Subject line'}
                />
                <BaseFormInputField
                  name={'emailFromName'}
                  label={'From name'}
                  isRequired={false}
                />
                {!isEnabledFeatureFlag && (
                  <>
                    <BaseFormInputField
                      name={'header'}
                      label={'Header text'}
                      isRequired={false}
                    />
                    <ReactTrix
                      ref={contentRef}
                      handleChange={(html) => {
                       void form.setFieldValue('content', html);
                      }}
                    />
                    <BaseFormInputField
                      name={'footer'}
                      label={'Footer text'}
                      isRequired={false}
                    />
                  </>
                )}
                <BaseFormSelectField
                  isRequired={false}
                  name={'status'}
                  label={'Send email'}
                  options={emailStatusOptions}
                />
                <BaseFormSelectField
                  isRequired={false}
                  name={'activeEmailTemplate'}
                  label={'Email templates'}
                  options={emailTemplatesOptions}
                />
                {errors.length > 0 && (
                  <UiStack spacing={4} flexGrow={1} py={4}>
                    {errors.map((error, index) => {
                      return (
                        <BaseMessageBarError key={index}>
                          {error}
                        </BaseMessageBarError>
                      );
                    })}
                  </UiStack>
                )}
              </BaseFormFieldGroup>
            </BaseFormDrawer>
          );
        }
      }
    </Formik>
  );
};

export default EditAutomatedEmailForm;
