import { type FC, useMemo } from 'react';
import { type OnChangeValue, type OptionBase, Select, type SelectComponent } from 'chakra-react-select';
import {
  uiStyles
} from '@/lib/ui';

export interface Option extends OptionBase {
  value: string | number | boolean
  label: string
}

export interface SelectElementProps extends Partial<SelectComponent> {
  onChange: (option: OnChangeValue<Option, false>) => void
  optionValue: Option['value']
  options: Option[]
  placeholder?: string
  isLoading?: boolean
  onScrollToBottom?: (event: WheelEvent | TouchEvent) => void
}

const SimpleSelectElement: FC<SelectElementProps> = ({
  optionValue,
  onChange,
  options,
  placeholder = '',
  onScrollToBottom,
  isLoading = false,
  ...props
}) => {
  const value = useMemo(
    () => {
      return options.find((opt) => opt.value === optionValue);
    },
    [options, optionValue]
  );

  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      size={'md'}
      focusBorderColor={'primary.500'}
      closeMenuOnSelect={true}
      onMenuScrollToBottom={onScrollToBottom}
      isLoading={isLoading}
      chakraStyles={{
        container: (provided) => ({ ...provided, borderColor: 'gray.300', bgColor: '#fff', borderRadius: uiStyles.formElementBorderRadius }),
        control: (provided) => ({ ...provided, borderRadius: uiStyles.formElementBorderRadius }),
        menu: (provided) => ({ ...provided, zIndex: 999999 }),
        menuList: (provided) => ({ ...provided, borderColor: 'gray.500', borderWidth: '1px', borderStyle: 'solid', borderRadius: uiStyles.formElementBorderRadius }),
        dropdownIndicator: (provided) => ({ ...provided, ...uiStyles.hover })
      }}
      placeholder={placeholder}
      {...props}
      isMulti={false}
    />
  );
};

export default SimpleSelectElement;
