import { useState, type FC } from 'react';
import { UiVStack } from '@/lib/ui';
import BaseBlockList from '@/base/BlockList';
import TenantRow from './TenantRow';
import { deleteEventWebsiteTenant, tenantListQueryKey, type EventWebsiteTenant } from '@/api/account/tenant';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useGlobalToast } from '@/lib/util';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import TenantInfo from '@/account/setup/Tenant/TenantInfo';

interface TenantListProps {
  data: EventWebsiteTenant[]
}

const TenantList: FC<TenantListProps> = ({ data }) => {
  const queryClient = useQueryClient();
  const { showToast } = useGlobalToast();
  const { tenantCode } = useTenantRoute();
  const { createTenantAdminApiRequest } = useTenantApi();
  const [selectedTenant, setSelectedTenant] = useState<EventWebsiteTenant | null>(null);

  const { mutate: deleteTenantMutate, isLoading: isDeleteTenantLoading } = useMutation({
    mutationFn: async (tenantId: number) => {
      return await deleteEventWebsiteTenant(createTenantAdminApiRequest)({ id: tenantId });
    },
    onSuccess: () => {
      showToast.success('Tenant deleted successfully');
      void queryClient.invalidateQueries({ queryKey: [tenantListQueryKey, { tenantCode }] });
      setSelectedTenant(null);
    }
  });

  const handleDelete = () => {
    if (!selectedTenant?.id) {
      showToast.error('Tenant ID is missing');
      return;
    }
    deleteTenantMutate(selectedTenant.id);
  };

  return (
    <UiVStack alignItems={'stretch'} spacing={4}>
      {data && Array.isArray(data) && data[0] && (
        <TenantInfo tenant={data[0]}/>
      )}
      {/*<BaseBlockList>*/}
      {/*  {data.map((tenant: EventWebsiteTenant) => {*/}
      {/*    return (*/}
      {/*      <TenantRow*/}
      {/*        key={tenant.id}*/}
      {/*        tenant={tenant}*/}
      {/*        setSelectedTenant={setSelectedTenant}*/}
      {/*        onDelete={handleDelete}*/}
      {/*        isLoading={isDeleteTenantLoading}*/}
      {/*      />*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</BaseBlockList>*/}
    </UiVStack>
  );
};

export default TenantList;
