import { Formik } from 'formik';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import CheckboxListField from '@/base/Form/CheckboxListField';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ReactTrix from './ReactTrix';
import { automatedEmailListKey, createAutomatedEmail } from '@/api/registration/automatedEmail';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { UiBox, UiStack } from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useGlobalToast } from '@/lib/util';
import { useEventQuery } from '@/registration/hook/useEventQuery';

interface AutomatedEmailFormProps {
  isOpen: boolean
  onToggle: () => void
  notificationOptions?: Option[]
}

interface FormData {
  eventId: string | number
  subject: string
  content?: string
  emailType: string
  emailFromName: string
  header: string
  footer?: string
  status: boolean
  includeInvoice: boolean
  sendEmail: string
}

const defaultValues = {
  subject: '',
  content: '',
  emailType: '',
  emailFromName: '',
  header: '',
  footer: '',
  status: false,
  includeInvoice: false,
  sendEmail: 'disabled',
};

const automatedEmailSchema = Yup.object().shape({
  subject: Yup.string().required('Subject line is required'),
});

const emailSendOptions = [{
  label: 'Yes',
  value: 'enabled'
}, {
  label: 'No',
  value: 'disabled'
}];

const CreateAutomatedEmailForm = ({ isOpen, onToggle, notificationOptions = [] }: AutomatedEmailFormProps) => {
  const queryClient = useQueryClient();
  const { showToast } = useGlobalToast();
  const { eventId } = useRegisterRoute();
  const [errors, setErrors] = useState<string[]>([]);
  const errorRef = useRef<HTMLHRElement | null>(null);
  const { createTenantAdminApiRequest } = useTenantApi();
  const { data: eventData } = useEventQuery(eventId);
  const isEnabledFeatureFlag = eventData?.enabledFlagEmailBuilder ?? false;
  const { mutate: createAutomatedEmailMutate, isLoading } = useMutation({
    mutationFn: async (params: {
      eventId: string | number
      subject: string
      emailType: string
      emailFromName: string
      header?: string
      content?: string
      footer?: string
      status: boolean
      includeInvoice: boolean
    }) => {
      return await createAutomatedEmail(createTenantAdminApiRequest)(params);
    },
    onSuccess: (data) => {
      if ((data.errors?.length ?? 0) > 0) {
        setErrors(data?.errors ?? []);
        if (errorRef.current) {
          errorRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        return;
      }
      showToast.success('Automated email created successfully');
      onToggle();
      void queryClient.invalidateQueries({
        queryKey: [automatedEmailListKey, { eventId }]
      });
    }
  });
  const handleSubmit = (values: FormData) => {
    createAutomatedEmailMutate(values);
  };
  return (
    <Formik<FormData>
      initialValues={{
        eventId,
        ...defaultValues
      }}
      validationSchema={automatedEmailSchema}
      onSubmit={handleSubmit}
    >
      {
        (form) => {
          return (
            <BaseFormDrawer
              isLoading={isLoading}
              title={'Create automated emails'}
              isOpen={isOpen}
              onClose={onToggle}
              size={'lg'}
            >
              <BaseFormFieldGroup>
                <BaseFormInputField
                  name={'subject'}
                  label={'Subject line'}
                />
                <BaseFormInputField
                  name={'emailFromName'}
                  label={'From name'}
                  isRequired={false}
                />
                {!isEnabledFeatureFlag && (
                  <>
                    <BaseFormInputField
                      name={'header'}
                      label={'Header text'}
                      isRequired={false}
                    />
                    <ReactTrix handleChange={(html) => {
                      void form.setFieldValue('content', html);
                    }} />
                    <BaseFormInputField
                      name={'footer'}
                      label={'Footer text'}
                      isRequired={false}
                    />
                  </>
                )}
                {/*<CheckboxListField*/}
                {/*  label={'Send email'}*/}
                {/*  name={'sendEmail'}*/}
                {/*  isMultiple={false}*/}
                {/*  isRequired={false}*/}
                {/*  options={emailSendOptions}*/}
                {/*/>*/}
                <BaseFormSelectField
                  isRequired={false}
                  name={'sendEmail'}
                  label={'Send email'}
                  options={emailSendOptions}
                />
                <BaseFormSelectField
                  isRequired={false}
                  name={'emailType'}
                  label={'Notification Type'}
                  options={notificationOptions}
                />
              </BaseFormFieldGroup>
              {errors.length > 0 && (
                <UiStack spacing={4} flexGrow={1} py={4}>
                  {errors.map((error, index) => {
                    return (
                      <BaseMessageBarError key={index}>
                        {error}
                      </BaseMessageBarError>
                    );
                  })}
                </UiStack>
              )}
              <UiBox ref={errorRef} />
            </BaseFormDrawer>
          );
        }
      }
    </Formik>
  );
};

export default CreateAutomatedEmailForm;
