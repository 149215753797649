import { useRef, type FC } from 'react';
import {
  UiButton, UiGrid,
  UiHStack, UiIconLayout, UiIconPencilSimple, UiIconX,
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiStack,
  type UiStackProps,
  uiStyles,
  UiTable,
  UiTableContainer,
  UiTd,
  UiText,
  UiTh,
  UiThead,
  UiTr,
} from '@/lib/ui';
import { Tbody } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type ICampaignsEmailRes } from '@/api/registration/campaignsEmail';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { useResizeObserver } from 'usehooks-ts';
import BaseBlockList from '@/base/BlockList';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';

const thStyles = {
  px: 8,
  py: 6
};
const tdStyles = {
  px: 8,
  py: 6,
};

interface ICampaignEmailListProps {
  data: ICampaignsEmailRes[]
  onCreateCampaignEmail?: (id: string | number) => void
  onDelete?: (id: string | number) => void
}

const CampaignEmailList: FC<ICampaignEmailListProps> = ({ data = [], onCreateCampaignEmail = () => { }, onDelete = () => { } }) => {
  const navigate = useNavigate();
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const { width = 0 } = useResizeObserver({
    ref: tableContainerRef,
  });

  const tableWrapperOverflow: UiStackProps = {
    overflowX: 'scroll',
    maxW: `${width}px`,
  };
  const { eventId, tenantCode } = useRegisterRoute();
  const { data: eventData } = useEventQuery(eventId);
  const isEnabledFeatureFlag = eventData?.enabledFlagEmailBuilder ?? false;
  const handleRedirect = ({ id }: { id: string | number }) => {
    navigate(generatePageUrl('RegistrationBuildEventCreateAutomatedEmail', { tenantCode, eventId }, { id }));
  };

  return (
    <BaseBlockList
      bgColor={'#fff'}
      enableDivider={true}
    >
      {data.map(({ id, subjectLine, event, sendEmail, type, emailTemplate = '' }) => {
        return (
          <UiHStack
            p={8}
            py={4}
            justifyContent={'space-between'}
          >
            <UiGrid
              templateColumns={'2fr 2fr 1fr 1fr 1fr'}
              gap={8}
              flexGrow={1}
            >
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Subject line
                </UiText>
                <UiText variant={'body2'}>
                  {subjectLine}
                </UiText>
              </UiStack>
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Type
                </UiText>
                <UiText variant={'body2'}>
                  {type}
                </UiText>
              </UiStack>
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Event
                </UiText>
                <UiText variant={'body2'}>
                  {event}
                </UiText>
              </UiStack>
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Email template
                </UiText>
                <UiText variant={'body2'}>
                  {emailTemplate}
                </UiText>
              </UiStack>
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Send email
                </UiText>
                <UiText variant={'body2'} color={sendEmail ? 'green.500': 'red.500'}>
                  {sendEmail ? 'Yes' : 'No'}
                </UiText>
              </UiStack>
            </UiGrid>
            <UiHStack spacing={4}>
              <BaseButtonPlainIcon
                label={"Edit"}
                Icon={UiIconPencilSimple}
                color={"primary.500"}
                onClick={() => { return onCreateCampaignEmail(id); }}
                iconSize={'xl'}
              />
              {isEnabledFeatureFlag && (
                <BaseButtonPlainIcon
                  label={"Design"}
                  Icon={UiIconLayout}
                  color={"primary.500"}
                  onClick={() => { return handleRedirect({ id }); }}
                  iconSize={'xl'}
                />
              )}
            </UiHStack>
          </UiHStack>
        );
      })}
    </BaseBlockList>
  );

  // return (
  //   <UiTableContainer ref={tableContainerRef} shadow={uiStyles.cardShadow} borderRadius={uiStyles.borderRadius} maxHeight={500} overflowY='scroll'>
  //     <UiStack {...tableWrapperOverflow}>
  //       <UiTable variant='simple' bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
  //         <UiThead>
  //           <UiTr>
  //             <UiTh {...thStyles} width='20%'><UiText fontWeight={600} variant={'body2'} textTransform="none" >Subject line</UiText></UiTh>
  //             <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Type</UiText></UiTh>
  //             <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Event</UiText></UiTh>
  //             <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Email template</UiText></UiTh>
  //             <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Send email</UiText></UiTh>
  //             <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textTransform="none" >Action</UiText></UiTh>
  //           </UiTr>
  //         </UiThead>
  //         <Tbody>
  //           {
  //             data.map(({ id, subjectLine, event, sendEmail, type, emailTemplate = '' }) => {
  //               return (
  //                 <UiTr key={id}>
  //                   <UiTd {...tdStyles} wordBreak='break-word'>{subjectLine}</UiTd>
  //                   <UiTd {...tdStyles}>{type}</UiTd>
  //                   <UiTd {...tdStyles}>{event}</UiTd>
  //                   <UiTd {...tdStyles}>{emailTemplate}</UiTd>
  //                   <UiTd {...tdStyles}>{sendEmail ? 'Yes' : 'No'}</UiTd>
  //                   <UiTd {...tdStyles}>
  //                     <UiMenu>
  //                       <UiMenuButton as={UiButton} size='sm'>
  //                         Actions
  //                       </UiMenuButton>
  //                       <UiMenuList>
  //                         <UiMenuItem onClick={() => { return onCreateCampaignEmail(id); }}>Manage Email Campaign</UiMenuItem>
  //                         {isEnabledFeatureFlag && <UiMenuItem onClick={() => { return handleRedirect({ id }); }}>Design Email</UiMenuItem>}
  //                       </UiMenuList>
  //                     </UiMenu>
  //                   </UiTd>
  //                 </UiTr>
  //               );
  //             })
  //           }
  //         </Tbody>
  //       </UiTable>
  //     </UiStack>
  //   </UiTableContainer>
  // );
};

export default CampaignEmailList;
