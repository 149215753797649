import { useState } from 'react';
import { useTenantApi } from '@/account/hook/useTenantApi';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { UiHStack, UiStack, UiTag } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ScheduledEmailList from './ScheduledEmailList';
import ScheduledEmailForm from './ScheduledEmailForm';
import { useDisclosure } from '@chakra-ui/react';
import { useGlobalToast } from '@/lib/util';
import { cancelScheduledEmail, loadScheduledEmails, scheduledEmailKey } from '@/api/registration/scheduledEmail';
import DeletionConfirmation from '../AttendeeCategories/DeletionConfirmation';

const ScheduledEmails = () => {
  const queryClient = useQueryClient();
  const { showToast } = useGlobalToast();
  const { isOpen, onToggle } = useDisclosure();
  const { eventId } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const [selectedEmailId, setSelectedEmailId] = useState<number | string | null>(null);
  const { isOpen: isCancelOpen, onClose: onCloseCancel, onOpen: onOpenCancel } = useDisclosure();

  const scheduledEmailQuery = useQuery({
    queryKey: [scheduledEmailKey, { eventId }],
    queryFn: async () => {
      return await loadScheduledEmails(createTenantAdminApiRequest)({ eventId });
    },
    enabled: !isApiPreparing
  });

  const { mutate: cancelScheduledEmaillMutate, isLoading: isCancelLoading } = useMutation({
    mutationFn: async () => {
      if (!selectedEmailId || !eventId) return;
      return await cancelScheduledEmail(createTenantAdminApiRequest)({ emailId: selectedEmailId, eventId });
    },
    onSuccess: (res) => {
      if ((res?.errors ?? []).length > 0) {
        showToast.error(res?.errors?.[0] ?? 'Failed to delete automated email');
        return;
      }
      void queryClient.invalidateQueries({
        queryKey: [scheduledEmailKey, { eventId }]
      });
      onCloseCancel();
    }
  });

  return (
    <QueryContainer query={scheduledEmailQuery}>
      {(scheduledEmailTableData) => {
        if (!scheduledEmailTableData.items.length) {
          return (<UiHStack>
            <UiTag colorScheme={'yellow'} px={4} py={2}>
              No scheduled emails found
            </UiTag>
          </UiHStack>);
        }
        return (
          <>
            <ScheduledEmailForm
              scheduledEmailTableData={scheduledEmailTableData.items}
              isOpen={isOpen}
              onToggle={onToggle}
              emailId={selectedEmailId}
              onClose={() => {
                setSelectedEmailId(null);
                onToggle();
              }} />
            <UiStack
              borderRadius={'lg'}
              spacing={4}
            >
              <ScheduledEmailList
                data={scheduledEmailTableData.items}
                onView={(id) => {
                  if (!id) return;
                  setSelectedEmailId(id);
                  onToggle();
                }}
                onCancel={(id) => {
                  if (!id) return;
                  setSelectedEmailId(id);
                  onOpenCancel();
                }}
              />
            </UiStack>
            <DeletionConfirmation isLoading={isCancelLoading} isOpen={isCancelOpen || isCancelLoading} onClose={onCloseCancel} name='schedule email' onConfirm={() => { return cancelScheduledEmaillMutate(); }} />
          </>
        );
      }}
    </QueryContainer>
  );
};

export default ScheduledEmails;
