import { Formik, type FormikProps } from 'formik';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import { useRef } from 'react';
import { type IScheduledEmailRes } from '@/api/registration/scheduledEmail';

interface ScheduledEmailFormProps {
  isOpen: boolean
  emailId: string | number | null
  onToggle: () => void
  onClose: () => void
  scheduledEmailTableData: IScheduledEmailRes[]
}

interface FormData extends Omit<IScheduledEmailRes, 'id'> { }

const ScheduledEmailForm = ({ isOpen, emailId, onClose, scheduledEmailTableData }: ScheduledEmailFormProps) => {
  const formRef = useRef<FormikProps<FormData> | null>(null);

  const scheduledEmailInfo = scheduledEmailTableData.find((item) => { return item.id === emailId; });

  return (
    <Formik<FormData>
      initialValues={scheduledEmailInfo ?? {
        dateTimeScheduled: '',
        subjectLine: '',
        event: '',
        type: '',
      }}
      onSubmit={() => { }}
      enableReinitialize
      innerRef={formRef}
    >
      {
        () => {
          return (
            <BaseFormDrawer
              title={'Scheduled email'}
              isOpen={isOpen}
              isHiddenButton
              onClose={() => {
                onClose();
              }}
              size={'lg'}
            >
              <BaseFormFieldGroup>
                <BaseFormInputField
                  isRequired={false}
                  label='Subject line'
                  name='subjectLine'
                  disabled
                />
                <BaseFormInputField
                  isRequired={false}
                  label='Type'
                  name='type'
                  disabled
                />
                <BaseFormInputField
                  isRequired={false}
                  label='Event'
                  name='event'
                  disabled />
                <BaseFormInputField
                  isRequired={false}
                  label='Date and Time Scheduled'
                  name='dateTimeScheduled'
                  disabled
                />
              </BaseFormFieldGroup>
            </BaseFormDrawer>
          );
        }
      }
    </Formik>
  );
};

export default ScheduledEmailForm;
