import {
  type ApiRequest,
  type ApiResponse,
  callApi,
  properCallApi,
  type ApiResponseSingle,
} from '@/api/tenantClient';
import { type RegistrationMode } from '../constant';
import { type TimezoneOption } from '@/base/Form/SelectField';

export interface Venue {
  name: string
}
export interface Event {
  id: string
  eventType: string
  name: string
  location: string
  description: string
  shortName: string
  tagline: string
  primaryColour: string
  secondaryColour: string
  startDate: string
  endDate: string
  coverImage: string
  registrationBanner: string
  menuLogo: string | null
  emailSignature: string
  startTime: string
  endTime: string
  websiteUrl: string
  mapUrl: string
  venues: Venue[]
  tzInfoName: string
  registrationMode: RegistrationMode
  timeZone: string
  cloneStatus?: string
  cloneable: boolean
  eventSettings: boolean
  built: boolean
  favourite: boolean
  financeCode: string
  financeCode2: string
  enabledFlagEmailBuilder: boolean
}

export interface EventLocale {
  currencyCode: string
  localeCode: string
}

export const eventQueryKey = "registration.event";

export const eventListQueryKey = 'registration.eventList';

export const timezoneOptionQueryKey = 'registration.timezoneOptionList';

export interface EventListData {
  items: Event[]
}

export interface EventListQueryRequest {
  page?: number
  size?: number
  search?: string
}

export function loadEventList(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: EventListQueryRequest): Promise<ApiResponse<Event>> => {
    request.method = "GET";
    request.endpoint.path = "/api/v1/events";
    if (!!params.page || !!params.search) {
      request.endpoint.query = params;
    }

    return await properCallApi<ApiResponse<Event>>(request);
  };
}

export interface EventQueryRequest {
  eventId: string;
}

export function loadEvent(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: EventQueryRequest): Promise<ApiResponse<Event>> => {
    request.method = "GET";
    request.endpoint.path = `/api/v1/events/${params.eventId}`;
    return await callApi<Event>(request);
  };
}

export interface EventUpdateRequest {
  event: Partial<Event>;
}

export function updateEvent(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: EventUpdateRequest): Promise<ApiResponse<Event>> => {
    if (params.event.id) {
      request.method = "PUT";
      request.endpoint.path = "/api/v1/events/" + params.event.id;
      request.payload = params;
    } else {
      request.method = "POST";
      request.endpoint.path = "/api/v1/events";
      request.payload = params;
    }
    return await properCallApi<ApiResponse<Event>>(request);
  };
}

export interface CloneEventRequest {
  eventFromId: string;
  eventToId: string;
}

export function cloneEvent(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: CloneEventRequest): Promise<ApiResponse<Event>> => {
    request.method = "POST";
    request.endpoint.path = "/api/v1/events/clone_registration_setup";
    request.payload = params;
    return await properCallApi<ApiResponse<Event>>(request);
  };
}

export function toggleFavourite(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: {
    eventId: string;
  }): Promise<ApiResponseSingle<any>> => {
    request.method = "PUT";
    request.endpoint.path = `/api/v1/events/${params.eventId}/toggle_favourite`;

    return await properCallApi<ApiResponseSingle<any>>(request);
  };
}

export function loadEventTimezoneOptions(
  createTenantRequest: () => ApiRequest
) {
  const request = createTenantRequest();
  return async (): Promise<ApiResponse<TimezoneOption>> => {
    request.method = "GET";
    request.endpoint.path = "/api/v1/events/timezone_options";
    return await properCallApi<ApiResponse<TimezoneOption>>(request);
  };
}

export function retrieveLocale(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: {
    eventId: string;
  }): Promise<ApiResponseSingle<EventLocale>> => {
    request.method = "GET";
    request.endpoint.path = `/api/v1/events/${params.eventId}/retrieve_locale`;
    return await properCallApi<ApiResponseSingle<EventLocale>>(request);
  };
}
