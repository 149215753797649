import { type JSONTemplate } from '@/registration/buildEvent/MailBuilder.tsx/MailBuilder';
import {
  type ApiRequest,
  properCallApi,
  type ApiResponse,
  type ApiResponseSingle,
} from '../tenantClient';

export type IEmailTypes = string[];

export interface ICategory {
  key: string
  label: string
  types: IEmailTypes
}

export const automatedEmailCategoriesKey = 'automatedEmailCategories';
export const automatedEmailListKey = 'automatedEmailList';

export const DEFAULT_CATEGORIES_ENABLED = ['cart_48_hours_reminder'];

export const loadCategoriesOptions = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (): Promise<ApiResponse<ICategory>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/automated_emails/categories';
    return await properCallApi<ApiResponse<ICategory>>(request);
  };
};

export interface IEmailItemBase {
  subjectLine: string
  type: string
  event: string
  emailTemplate: string | null
  sendEmail: boolean
  sendPushNotification: boolean
}

export interface IEmailRes extends IEmailItemBase {
  id: number
  createdAt: string
  updatedAt: string
}

export type AutomatedEmailRes = Record<keyof Omit<IEmailTableRes, 'newEmailTypes'>, IEmailRes[]>;

export interface IEmailTableRes {
  agendaEmails: IEmailRes[]
  customEmails: IEmailRes[]
  engagementEmails: IEmailRes[]
  registrationConfirmationEmails: IEmailRes[]
  registrationManagementEmails: IEmailRes[]
  sponsorsExhibitorsEmails: IEmailRes[]
  usersEmails: IEmailRes[]
  newEmailTypes: string[]
}

export const loadEmailTable = (createTenantAdminRequest: () => ApiRequest) => {
  const request = createTenantAdminRequest();

  return async (params: {
    eventId: string | number
  }): Promise<ApiResponseSingle<IEmailTableRes>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/automated_emails';
    request.endpoint.query = { event_id: params.eventId };
    return await properCallApi<ApiResponseSingle<IEmailTableRes>>(request);
  };
};

export interface IHtmlTemplate {
  id: number
  body: string
  name: string
  createdAt: string
  locale: string
  recordId: number
  recordType: string
  updatedAt: string
}

export interface ILoadEmailTemplateRes {
  active: boolean
  createdAt: string
  createdBy?: string
  deletedAt?: string
  emailId: number
  html: IHtmlTemplate
  id: number
  name: string
  template: JSONTemplate
  updatedAt: string
  updatedById?: string
}

export const loadEmailTemplate = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (params: {
    emailId: string | number
    eventId: string | number
  }): Promise<ApiResponse<ILoadEmailTemplateRes>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/email_templates';
    request.endpoint.query = {
      event_id: params.eventId,
      email_id: params.emailId,
    };
    return await properCallApi<ApiResponse<ILoadEmailTemplateRes>>(request);
  };
};

export const createAutomatedEmail = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (params: {
    eventId: string | number
    subject: string
    emailType: string
    emailFromName: string
    header?: string
    content?: string
    footer?: string
    status: boolean
    includeInvoice: boolean
  }): Promise<ApiResponseSingle<IEmailRes>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/admin/automated_emails';
    request.payload = params;
    return await properCallApi<ApiResponseSingle<IEmailRes>>(request);
  };
};

export interface IEditAutomatedEmail {
  id: number
  emailType: string
  subject: string
  emailFromName: string
  header: string
  content: string
  footer: string
  includeInvoice: boolean
  pushNotificationStatus: boolean
  pushNotificationTitle: string
  pushNotificationBody: string
  status: boolean | string
  emailTemplates: Array<{
    id: number
    name: string
  }>
  activeEmailTemplate: number
  createdAt: string
  updatedAt: string
}

export const getAutomatedEmail = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (params: {
    emailId: string | number
    eventId: string | number
  }): Promise<ApiResponseSingle<IEditAutomatedEmail>> => {
    request.method = 'GET';
    request.endpoint.path = `/api/v1/admin/automated_emails/${params.emailId}`;
    request.endpoint.query = { event_id: params.eventId };
    return await properCallApi<ApiResponseSingle<IEditAutomatedEmail>>(request);
  };
};

export interface IAutomatedEmailParams {
  eventId: string | number
  emailId: string | number
  subject: string
  content?: string
  emailType: string
  emailFromName: string
  header: string
  footer?: string
  status: boolean
  includeInvoice: boolean
  activeEmailTemplate: number
}

export const editAutomatedEmail = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (params: IAutomatedEmailParams): Promise<ApiResponseSingle<IEmailRes>> => {
    const { emailId, ...payload } = params;
    request.method = 'PUT';
    request.endpoint.path = `/api/v1/admin/automated_emails/${emailId}`;
    request.payload = payload;
    return await properCallApi<ApiResponseSingle<IEmailRes>>(request);
  };
};

export interface IEmailTemplatePayload {
  html: string
  template: JSONTemplate
}

export interface IUpdateEmailTemplateParams {
  emailTemplateId: string | number
  payload: IEmailTemplatePayload
}

export interface IUpdatedEmailTemplateRes {
  id: number
  name: string
  template: JSONTemplate
  html: IHtmlTemplate
  active: boolean
  emailId: number
  createdAt: string
  updatedAt: string
  createdById?: string
  updatedById?: string
  deleteAt?: string
}

export const updateEmailTemplate = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (params: IUpdateEmailTemplateParams): Promise<ApiResponseSingle<IUpdatedEmailTemplateRes>> => {
    const { emailTemplateId, payload } = params;
    request.method = 'PUT';
    request.endpoint.path = `/api/v1/admin/email_templates/${emailTemplateId}`;
    request.payload = payload;
    return await properCallApi<ApiResponseSingle<IUpdatedEmailTemplateRes>>(request);
  };
};

export const createEmailTemplate = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (params: {
    name: string
    emailId: string | number
    eventId: string | number
    createBlank: boolean
    html: string
    template: JSONTemplate
  }): Promise<ApiResponseSingle<IUpdatedEmailTemplateRes>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/admin/email_templates';
    request.payload = params;
    return await properCallApi<ApiResponseSingle<IUpdatedEmailTemplateRes>>(request);
  };
};

export const deleteEmailTemplate = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (params: {
    emailTemplateId: string | number
  }): Promise<ApiResponseSingle<IUpdatedEmailTemplateRes>> => {
    request.method = 'DELETE';
    request.endpoint.path = `/api/v1/admin/email_templates/${params.emailTemplateId}`;
    return await properCallApi<ApiResponseSingle<IUpdatedEmailTemplateRes>>(request);
  };
};

export const activateEmailTemplate = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (params: {
    emailTemplateId: string | number
  }): Promise<ApiResponseSingle<IUpdatedEmailTemplateRes>> => {
    request.method = 'PUT';
    request.endpoint.path = `/api/v1/admin/email_templates/${params.emailTemplateId}/activate`;
    return await properCallApi<ApiResponseSingle<IUpdatedEmailTemplateRes>>(request);
  };
};

export const deleteAutomatedEmail = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (params: { emailId: string | number }) => {
    request.method = 'DELETE';
    request.endpoint.path = `/api/v1/admin/automated_emails/${params.emailId}`;
    return await properCallApi<ApiResponseSingle<any>>(request);
  };
};
