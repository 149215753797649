import dayjs from 'dayjs';
import {
  type ApiResponse,
  properCallApi,
  type ApiRequest,
  type ApiResponseSingle,
} from '../tenantClient';

export const campaignsEmailKey = 'campaignsEmail';

export interface ICampaignsEmailRes {
  id: number
  subjectLine: string
  type: string
  event: string
  emailTemplate: string | null
  sendEmail: boolean
  sendPushNotification: boolean
}

export interface ICampaignEmailParams {
  eventId: string | number
}

export const getCampaignsEmail = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (
    params: ICampaignEmailParams
  ): Promise<ApiResponse<ICampaignsEmailRes>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/email_campaigns';
    request.endpoint.query = {
      event_id: params.eventId,
    };
    return await properCallApi<ApiResponse<ICampaignsEmailRes>>(request);
  };
};

export interface IUserOptions {
  id: number
  text: string
}

export const loadUserOptions = (createTenantAdminRequest: () => ApiRequest) => {
  const request = createTenantAdminRequest();

  return async ({
    page,
  }: {
    page: number
  }): Promise<ApiResponse<IUserOptions>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/email_campaigns/user_options';
    request.endpoint.query = {
      page,
    };
    return await properCallApi<ApiResponse<IUserOptions>>(request);
  };
};

export interface ICreateEmailCampaignParams {
  eventId: string | number
  emailId: string | number
  delegateTypeIds: string[] | number[]
  attendeeCategoryIds: string[] | number[]
  userIds: string[] | number[]
  exhibitorTypeIds: string[] | number[]
  sponsorTierIds: string[] | number[]
  speakerTypeIds: string[] | number[]
  pushAt: string
}

export const createEmailCampaign = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (
    params: ICreateEmailCampaignParams
  ): Promise<ApiResponseSingle<any>> => {
    const pushAt = dayjs(params.pushAt).format('DD/MM/YYYY HH:mm');
    request.method = 'POST';
    request.endpoint.path = '/api/v1/admin/email_campaigns';
    request.payload = { ...params, pushAt };
    return await properCallApi<ApiResponseSingle<any>>(request);
  };
};

export interface IEmailCampaignFormParams {
  eventId: string | number
}

export interface IFormOption {
  0: string
  1: number
}
export interface IEmailCampaignFormRes {
  emailTemplates: IFormOption[]
  delegateTypes: IFormOption[]
  attendeeCategories: IFormOption[]
  exhibitorTypes: IFormOption[]
  speakerTypes: IFormOption[]
  sponsorTiers: IFormOption[]
}

export const getEmailCampaignForm = (
  createTenantAdminRequest: () => ApiRequest
) => {
  const request = createTenantAdminRequest();

  return async (params: IEmailCampaignFormParams): Promise<ApiResponseSingle<IEmailCampaignFormRes>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/email_campaigns/form';
    request.endpoint.query = {
      event_id: params.eventId,
    };
    return await properCallApi<ApiResponseSingle<IEmailCampaignFormRes>>(request);
  };
};
