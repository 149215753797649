import {
  UiBox,
  UiButton,
  UiDivider,
  UiGrid,
  UiGridItem,
  UiHStack,
  UiIconCaretLeft,
  UiIconX,
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalOverlay,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import BaseFloatingContainerTop from '@/base/FloatingContainer/Top';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import EmailEditor, { type EditorRef } from 'react-email-editor';
import { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { activateEmailTemplate, createEmailTemplate, deleteEmailTemplate, type IEmailTemplatePayload, loadEmailTemplate, updateEmailTemplate } from '@/api/registration/automatedEmail';
import BaseSimpleSelectElement, { type Option as SelectElementOption } from '@/base/Form/SimpleSelectElement';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import { AddIcon, EditIcon, ExternalLinkIcon, HamburgerIcon, RepeatIcon } from '@chakra-ui/icons';
import { type OnChangeValue } from 'chakra-react-select';
import BaseFormInputField from '@/base/Form/InputField';
import { Form, Formik } from 'formik';
import defaultTemplate from './template.json';
import { useGlobalToast } from '@/lib/util';
import { type JSONTemplate } from './MailBuilder';

enum SaveEmailType {
  SAVE = 'save',
  SAVE_AS = 'save_as',
  NEW = 'new',
  DELETE = 'delete',
  ACTIVATE = 'activate',
}

interface CancelButtonProps {
  tenantCode: string
  eventId: string
}

interface ICreateTemplatePayload extends IEmailTemplatePayload {
  name: string
  createBlank: boolean
}

const CancelButton: FC<CancelButtonProps> = ({ tenantCode, eventId }) => {
  return (
    <BaseRouterLink
      to={generatePageUrl('RegistrationBuildEventAutomatedEmail', { tenantCode, eventId })}
    >
      <UiHStack {...uiStyles.hover}>
        <UiIconCaretLeft color={'primary.500'} size={'2xl'} />
      </UiHStack>
    </BaseRouterLink>
  );
};

const templateQueryKey = 'mailTemplate';

const EMAIL_TEMPLATE_OPTIONS = {
  [SaveEmailType.NEW]: 'New Template',
  [SaveEmailType.SAVE]: 'Save',
  [SaveEmailType.SAVE_AS]: 'Save As...',
  [SaveEmailType.DELETE]: 'Delete',
  [SaveEmailType.ACTIVATE]: 'Make template active',
};

const ACTION_TEMPLATE_LABEL = {
  [SaveEmailType.NEW]: 'Creating new',
  [SaveEmailType.SAVE]: 'Saving',
  [SaveEmailType.SAVE_AS]: 'Save as ',
  [SaveEmailType.DELETE]: 'Delete',
  [SaveEmailType.ACTIVATE]: 'Activating',
};

const MailBuilder = () => {
  const queryClient = useQueryClient();
  const { showToast } = useGlobalToast();
  const [saveType, setSaveType] = useState<SaveEmailType>(SaveEmailType.SAVE);
  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState<number | null>(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { tenantCode, eventId } = useRegisterRoute();
  const emailEditorRef = useRef<EditorRef>(null);
  const { createTenantAdminApiRequest, isLoaded } = useTenantApi();
  const [searchParams] = useSearchParams();
  const emailId = searchParams.get('id');

  const handleLoadingToast = () => {
    const templateName = emailTemplateOptions.find(({ value }) => { return value === selectedEmailTemplateId; })?.label;
    showToast.loading(`${ACTION_TEMPLATE_LABEL[saveType]} email template ${templateName}`);
  };

  const { data, isSuccess } = useQuery({
    queryKey: [templateQueryKey, { emailId, eventId }],
    queryFn: async () => {
      if (!emailId || !eventId) {
        return null;
      }
      return await loadEmailTemplate(createTenantAdminApiRequest)({
        emailId,
        eventId,
      });
    },
    enabled: isLoaded && !!emailId,
  });

  const { mutate: updateEmailTemplateMutate } = useMutation({
    mutationFn: async (params: IEmailTemplatePayload) => {
      if (!selectedEmailTemplateId) {
        return null;
      }
      return await updateEmailTemplate(createTenantAdminApiRequest)({
        emailTemplateId: selectedEmailTemplateId,
        payload: params,
      });
    },
    onMutate: handleLoadingToast,
    onSuccess: (res) => {
      if ((res?.errors?.length ?? 0) > 0) {
        showToast.error(String(res?.errors?.[0] ?? 'Something went wrong'));
        return;
      }
      void queryClient.invalidateQueries({ queryKey: [templateQueryKey, { emailId, eventId }] });
      showToast.success('Email template updated successfully');
    }
  });

  const { mutate: createOrSaveAsEmailTemplateMutate, isLoading: isCreateLoading } = useMutation({
    mutationFn: async (payload: ICreateTemplatePayload) => {
      if (!emailId) return;
      return await createEmailTemplate(createTenantAdminApiRequest)({
        eventId,
        emailId,
        ...payload,
      });
    },
    onSuccess: (res) => {
      if (((res?.errors ?? []).length) > 0) {
        showToast.error(String(res?.errors?.[0] ?? 'Something went wrong'));
        return;
      }
      void queryClient.invalidateQueries({ queryKey: [templateQueryKey, { emailId, eventId }] });
      setSelectedEmailTemplateId(res?.item?.id ?? selectedEmailTemplateId);
      showToast.success('Email template created successfully');
      onClose();
    }
  });

  const { mutate: deleteTemplateMutate } = useMutation({
    mutationFn: async () => {
      if (!selectedEmailTemplateId) {
        return null;
      }
      return await deleteEmailTemplate(createTenantAdminApiRequest)({ emailTemplateId: selectedEmailTemplateId });
    },
    onMutate: handleLoadingToast,
    onSuccess: (res) => {
      if (((res?.errors ?? []).length) > 0) {
        showToast.error(String(res?.errors?.[0] ?? 'Something went wrong'));
        return;
      }
      const [defaultOption] = emailTemplateOptions;

      setSelectedEmailTemplateId(defaultOption.value);
      void queryClient.invalidateQueries({ queryKey: [templateQueryKey, { emailId, eventId }] });
      showToast.success('Email template deleted successfully');
    }
  });

  const { mutate: activateEmailTemplateMutate } = useMutation({
    mutationFn: async () => {
      if (!selectedEmailTemplateId) {
        return null;
      }
      return await activateEmailTemplate(createTenantAdminApiRequest)({ emailTemplateId: selectedEmailTemplateId });
    },
    onMutate: handleLoadingToast,
    onSuccess: (res) => {
      if (((res?.errors ?? []).length) > 0) {
        showToast.error(res?.errors?.[0] ?? 'Error activating email template');
        return;
      }
      void queryClient.invalidateQueries({ queryKey: [templateQueryKey, { emailId, eventId }] });
      showToast.success('Email template activated successfully');
    }
  });

  const handleSave = <T extends IEmailTemplatePayload | ICreateTemplatePayload>(
    saveAction: (saveActionPayload: T) => void
  ) => {
    return (payload: T) => {
      const unlayer = emailEditorRef.current?.editor;
      unlayer?.saveDesign((template: JSONTemplate) => {
        saveAction({ ...payload, template, html: '' });
      });
    };
  };

  const emailTemplateOptions = useMemo(() => {
    if (!data?.items.length) return [];
    return data.items.map(({ id, name, active }) => {
      const label: string = active ? `${name} (Active)` : name;
      return {
        value: id,
        label,
        active
      };
    });
  }, [data]);

  useEffect(() => {
    if (emailEditorRef.current && isSuccess) {
      if ((data?.items?.length ?? 0) > 0) {
        const emailTemplate = data?.items.find((item) => { return item.id === selectedEmailTemplateId; }) as IEmailTemplatePayload | undefined;
        if (emailTemplate?.template) {
          emailEditorRef.current.editor?.loadDesign(emailTemplate.template);
        }
      }
    }
  }, [data, emailTemplateOptions, isSuccess, selectedEmailTemplateId]);

  useEffect(() => {
    if (emailTemplateOptions.length > 0) {
      const [defaultOption] = emailTemplateOptions;
      const activatedEmailTemplate = emailTemplateOptions.find(({ active }) => { return active; });
      const defaultEmailTemplate = activatedEmailTemplate ?? defaultOption;
      setSelectedEmailTemplateId(defaultEmailTemplate.value);
    }
  }, [isSuccess]);

  const handleOnChange = async (option: OnChangeValue<SelectElementOption, false>) => {
    setSelectedEmailTemplateId(option?.value as number);
  };
  const handleMenu = (menuActionType: SaveEmailType) => {
    setSaveType(menuActionType);
    onToggle();
  };

  return (
    <UiStack
      bgColor={'gray.100'}
      alignItems={'stretch'}
      borderRadius={uiStyles.borderRadius}
      minHeight={'100vh'}
      flexGrow={1}
      pb={12}
    >
      <BaseFloatingContainerTop heightOffset={1}>
        <UiStack
          spacing={0}
          bgColor={'#fff'}
        >
          <UiHStack
            px={8}
            py={6}
            alignItems={'stretch'}
            justifyContent={'space-between'}
          >
            <CancelButton tenantCode={tenantCode} eventId={eventId} />
            <UiText variant='title'>Email builder</UiText>
            <UiHStack/>
          </UiHStack>
        </UiStack>
      </BaseFloatingContainerTop>

      <UiStack
        spacing={4}
        flexGrow={1}
        alignItems={'center'}
        pt={8}
      >
        <UiStack
          p={4}
          minW={1200}
          maxW={1200}
          minH={1000}
          maxH={1000}
          background={'#fff'}
          alignItems={'stretch'}
          borderRadius={uiStyles.bodyRadius}
          boxShadow={uiStyles.cardShadow}
        >
          <UiModal isOpen={isOpen} onClose={onToggle}>
            <UiModalOverlay />
            <UiModalContent>
              <UiModalCloseButton />
              <UiModalBody pb={4}>
                <Formik
                  initialValues={{ name: '' }}
                  onSubmit={(params) => {
                    const payload = {
                      name: params.name,
                      createBlank: SaveEmailType.NEW === saveType,
                      html: '',
                      template: defaultTemplate,
                    };
                    if (saveType === SaveEmailType.SAVE_AS) {
                      handleSave(createOrSaveAsEmailTemplateMutate)(payload);
                    } else if (saveType === SaveEmailType.NEW) {
                      createOrSaveAsEmailTemplateMutate(payload);
                    }
                  }}>
                  {
                    (form) => {
                      return <Form id='new-template-form' onSubmit={form.handleSubmit}>
                        <UiStack direction={'column'}>
                          <BaseFormInputField label={EMAIL_TEMPLATE_OPTIONS[saveType]} name='name' layout='stack' />
                        </UiStack>
                      </Form>;
                    }
                  }
                </Formik>
              </UiModalBody>
              <UiDivider />
              <UiModalFooter>
                <UiStack direction={'row'} justifyContent={'flex-end'} spacing={4}>
                  <UiButton variant='outline'>Cancel</UiButton>
                  <UiButton type='submit' form='new-template-form' isLoading={isCreateLoading}>Save</UiButton>
                </UiStack>
              </UiModalFooter>
            </UiModalContent>
          </UiModal>
          <UiGrid templateColumns={'repeat(5, 1fr)'} gap={4}>
            <UiGridItem gridColumn="4 / span 2" display={'flex'} justifyContent={'flex-end'} gap={4}>
              <UiBox width={'100%'}>
                {!!selectedEmailTemplateId && <BaseSimpleSelectElement
                  optionValue={selectedEmailTemplateId}
                  onChange={handleOnChange}
                  options={emailTemplateOptions}
                  placeholder='Select template'
                />}
              </UiBox>
              <UiMenu>
                <UiMenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='outline'
                />
                <UiMenuList>
                  <UiMenuItem
                    icon={<AddIcon />}
                    command='⌘T'
                    onClick={() => { return handleMenu(SaveEmailType.NEW); }}>
                    New
                  </UiMenuItem>
                  <UiMenuItem icon={<ExternalLinkIcon />}
                    command='⌘N'
                    onClick={() => {
                      const payload = {
                        html: '',
                        template: defaultTemplate,
                      };
                      handleSave(updateEmailTemplateMutate)(payload);
                    }}>
                    Save
                  </UiMenuItem>
                  <UiMenuItem icon={<RepeatIcon />}
                    command='⌘⇧N'
                    onClick={() => { return handleMenu(SaveEmailType.SAVE_AS); }}>
                    Save As...
                  </UiMenuItem>
                  <UiMenuItem icon={<EditIcon />}
                    command='⌘O'
                    onClick={() => {
                      setSaveType(SaveEmailType.DELETE);
                      return deleteTemplateMutate();
                    }}>
                    Delete
                  </UiMenuItem>
                  <UiMenuItem icon={<EditIcon />}
                    command='⌘M'
                    onClick={() => {
                      setSaveType(SaveEmailType.ACTIVATE);
                      return activateEmailTemplateMutate();
                    }}>
                    Make template active
                  </UiMenuItem>
                </UiMenuList>
              </UiMenu>
            </UiGridItem>
          </UiGrid>
          <EmailEditor ref={emailEditorRef} />
        </UiStack>
      </UiStack>
    </UiStack>
  );
};

export default MailBuilder;
