import { type FC, useCallback, useMemo, useState } from 'react';
import { camelCase } from 'lodash';
import BaseLayout from '@/base/Layout';
import {
  UiStack,
  UiSpinner, UiButton, UiHStack,
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useQuery } from '@tanstack/react-query';
import { automatedEmailCategoriesKey, loadCategoriesOptions } from '@/api/registration/automatedEmail';
import { useTenantApi } from '@/account/hook/useTenantApi';
import AutomatedList, { type AutomatedOptions } from './AutomatedList';
import CampaignEmails from '@/registration/buildEvent/AutomatedEmail/CampaignEmails';
import ScheduledEmails from '@/registration/buildEvent/AutomatedEmail/ScheduledEmails';
import BaseAclContainer from '@/account/component/AclContainer';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';

const AdditionalInfoForm: FC = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { mutate, isLoading: isNextLoading } = useIsSetMutation();
  const { toNextStep } = useBuildEventSteps();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { data, isLoading } = useQuery({
    queryKey: [automatedEmailCategoriesKey],
    queryFn: async () => {
      return await loadCategoriesOptions(createTenantAdminApiRequest)();
    },
  });

  const onNext = useCallback(
    () => {
      mutate({ eventId, key: 'paymentMethod', bool: true }, {
        onSuccess: () => {
          toNextStep();
        }
      });
    },
    [mutate, eventId, toNextStep]
  );

  const categoriesOptions = useMemo(() => {
    if (!data?.items.length) return [];
    return data.items.map(({ label, key: value }) => {
      return {
        value: camelCase(value),
        label,
      };
    });
  }, [data]);

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
        MainTransition={null}
      >
        <ManageEventLayoutBody
          steps={(
            <ManageEventSteps
              eventId={eventId}
              activeStep={'automatedEmail'}
            />
          )}
          message={'Group your input fields.'}
        >
          <UiStack
            flexGrow={1}
            p={8}
            justifyContent={'flex-start'}
            spacing={8}
          >
            {/*<UiSimpleGrid columns={{base: 1, 'lg': 2, '2xl': 3}} spacing={8}>*/}
            {/*  <UiStack>*/}
            {/*    <SimpleMultiSelectElement*/}
            {/*      placeholder='Select categories '*/}
            {/*      onChange={handleChange as unknown as (newValue: OnChangeValue<any, boolean>, actionMeta: ActionMeta<any>) => void}*/}
            {/*      optionValue*/}
            {/*      isLoading={isLoading}*/}
            {/*      options={categoriesOptions}*/}
            {/*      value={selectedCategories}*/}
            {/*    />*/}
            {/*  </UiStack>*/}
            {/*</UiSimpleGrid>*/}
            {/*<BaseDividerHorizontal height={4} />*/}
            {/*<AutomatedList categories={selectedCategories} />*/}
            {isLoading ? (
              <UiSpinner size={'md'} color={'primary.500'} thickness='2px' />
            ) : (
              <AutomatedList categories={categoriesOptions as AutomatedOptions[]} />
            )}
            <CampaignEmails/>
            <ScheduledEmails/>
          </UiStack>
          <UiHStack
            p={8}
            justifyContent={'space-between'}
            // flexDirection={'row-reverse'}
          >
            <UiStack flexGrow={1} />
            <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
              <UiButton
                size={'lg'}
                colorScheme={'primary'}
                type={'submit'}
                fontSize={'lg'}
                onClick={onNext}
                isLoading={isNextLoading}
              >
                Next
              </UiButton>
            </BaseAclContainer>
          </UiHStack>
        </ManageEventLayoutBody>
      </BaseLayout>
    </>
  );
};

export default AdditionalInfoForm;
