import { type ToastId, type UseToastOptions, useToast } from '@chakra-ui/react';
import { useRef } from 'react';

interface useToastProps extends UseToastOptions {
  margin?: string
}

export const useGlobalToast = () => {
  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);

  const showToastOption = (options: useToastProps) => {
    const toastOptions: useToastProps = {
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      containerStyle: {
        margin: options.margin ? options.margin : '70px 30px auto auto',
      },
      ...options,
    };
    if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
      toast.update(toastIdRef.current, toastOptions);
    } else {
      toastIdRef.current = toast(toastOptions);
    }
  };

  const createShowToast = (
    status: 'success' | 'error' | 'warning' | 'info' | 'loading'
  ) => {
    return (message: string, options?: useToastProps) => {
      showToastOption({
        status,
        description: message,
        ...options,
      });
    };
  };

  const showToast = {
    success: createShowToast('success'),
    error: createShowToast('error'),
    warning: createShowToast('warning'),
    info: createShowToast('info'),
    loading: createShowToast('loading'),
  };

  return {
    showToast,
  };
};
