import { type FC, useCallback, useState } from 'react';
import {
  UiStack,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiHStack,
  uiStyles,
  UiIconCaretDown,
} from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import FieldInputForm from './FieldInputForm';
import FieldSelectForm from './FieldSelectForm';
import FieldSectionBreakForm from './FieldSectionBreakForm';
import FieldDescriptionBlockForm from './FieldDescriptionBlockForm';
import { type FieldMetadataDescriptionBlock, type FieldMetadata, type FieldMetadataInput, type FieldMetadataSectionBreak, type FieldMetadataSelect, type FieldType, type FieldMetadataDate, type FieldMetadataPhone } from '@/base/FormBuilder/Field';
import FieldDateForm from './FieldDateForm';
import FieldPhoneForm from './FieldPhoneForm';
import { UiIconCheckBoxCircle } from './UiIconCheckBoxCircle';

export interface FieldDrawerProps {
  title: string
  isOpen: UiDrawerProps['isOpen']
  onClose: UiDrawerProps['onClose']
  size?: UiDrawerProps['size']
  isLoading?: boolean
  initFieldType?: FieldType
  initFieldMetadata?: FieldMetadata
  onFieldAdd?: (fieldMetadata: FieldMetadata) => void
  onFieldUpdate?: (fieldMetadata: FieldMetadata) => void
}

const FieldDrawer: FC<FieldDrawerProps> = ({
  title,
  isOpen,
  onClose,
  size = '2xl',
  initFieldType = 'input',
  initFieldMetadata = undefined,
  isLoading = false,
  onFieldAdd = () => {},
  onFieldUpdate = () => {}
}) => {
  const [fieldType, setFieldType] = useState<FieldType>(initFieldType);
  const onFieldSave = useCallback(
    (fieldMetadata: FieldMetadata) => {
      if (initFieldMetadata) {
        onFieldUpdate(fieldMetadata);
      } else {
        onFieldAdd(fieldMetadata);
      }
    },
    [onFieldUpdate, onFieldAdd, initFieldMetadata]
  );

  return (
    <UiDrawer placement={'right'} size={size} isOpen={isOpen} onClose={onClose}>
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerBody p={8}>
          <UiText variant={'h5'} pb={8}>{title}</UiText>
          <UiHStack flexGrow={1} height={'100%'} alignItems={'stretch'} spacing={0}>
            {initFieldMetadata === undefined && (
              <UiStack
                // maxWidth={'400px'}
                minWidth={'400px'}
                flexGrow={1}
                borderRightWidth={'1px'}
                borderRightColor={'gray.200'}
                borderRightStyle={'solid'}
                pr={8}
                // bgColor={'red'}
              >
                <UiStack
                  spacing={8}
                  pt={4}
                  // p={8}
                  // bgColor={'gray.50'}
                  // bgColor={'blackAlpha.50'}
                  // width={'300px'}
                  borderRadius={uiStyles.borderRadius}
                  // divider={<UiDivider borderColor={'gray.300'}/>}
                  flexGrow={1}
                >
                  <UiHStack
                    borderRadius={uiStyles.borderRadius}
                    spacing={4}
                    onClick={() => { setFieldType('input'); }}
                    {...uiStyles.hover}
                  >
                    <UiIconCheckBoxCircle value={fieldType === 'input'} />
                    <UiStack
                      borderRadius={uiStyles.formElementBorderRadius}
                      borderWidth={'1px'}
                      borderStyle={'solid'}
                      borderColor={fieldType === 'input' ? 'primary.500' : 'gray.300'}
                      // bgColor={'gray.200'}
                      bgColor={fieldType === 'input' ? 'primary.50' : '#fff'}
                      flexGrow={1}
                      p={4}
                      py={2}
                    >
                      <UiText color={'text.secondary'} variant={'body1'}>Text input</UiText>
                    </UiStack>
                  </UiHStack>
                  <UiHStack
                    borderRadius={uiStyles.borderRadius}
                    spacing={4}
                    onClick={() => { setFieldType('select'); }}
                    {...uiStyles.hover}
                  >
                    <UiIconCheckBoxCircle value={fieldType === 'select'} />
                    <UiHStack
                      flexGrow={1}
                      borderRadius={uiStyles.formElementBorderRadius}
                      borderWidth={'1px'}
                      borderStyle={'solid'}
                      borderColor={fieldType === 'select' ? 'primary.500' : 'gray.300'}
                      bgColor={fieldType === 'select' ? 'primary.50' : '#fff'}
                      justifyContent={'space-between'}
                      px={4}
                      py={2}
                    >
                      <UiText color={'text.secondary'} variant={'body1'}>Select</UiText>
                      <UiIconCaretDown size={'2xl'} color={'gray.400'} />
                    </UiHStack>
                  </UiHStack>
                  <UiHStack
                    borderRadius={uiStyles.borderRadius}
                    spacing={4}
                    onClick={() => { setFieldType('date'); }}
                    {...uiStyles.hover}
                  >
                    <UiIconCheckBoxCircle value={fieldType === 'date'} />
                    <UiStack
                      borderRadius={uiStyles.formElementBorderRadius}
                      borderWidth={'1px'}
                      borderStyle={'solid'}
                      borderColor={fieldType === 'date' ? 'primary.500' : 'gray.300'}
                      // bgColor={'gray.200'}
                      bgColor={fieldType === 'date' ? 'primary.50' : '#fff'}
                      flexGrow={1}
                      p={4}
                      py={2}
                    >
                      <UiText color={'text.secondary'} variant={'body1'}>Date (dd/mm/yyyy)</UiText>
                    </UiStack>
                  </UiHStack>
                  <UiHStack
                    borderRadius={uiStyles.borderRadius}
                    spacing={4}
                    onClick={() => { setFieldType('phone'); }}
                    {...uiStyles.hover}
                  >
                    <UiIconCheckBoxCircle value={fieldType === 'phone'} />
                    <UiStack
                      borderRadius={uiStyles.formElementBorderRadius}
                      borderWidth={'1px'}
                      borderStyle={'solid'}
                      borderColor={fieldType === 'phone' ? 'primary.500' : 'gray.300'}
                      // bgColor={'gray.200'}
                      bgColor={fieldType === 'phone' ? 'primary.50' : '#fff'}
                      flexGrow={1}
                      p={4}
                      py={2}
                    >
                      <UiText color={'text.secondary'} variant={'body1'}>Phone number</UiText>
                    </UiStack>
                  </UiHStack>
                  <UiHStack
                    borderRadius={uiStyles.borderRadius}
                    spacing={4}
                    onClick={() => { setFieldType('sectionBreak'); }}
                    {...uiStyles.hover}
                  >
                    <UiIconCheckBoxCircle value={fieldType === 'sectionBreak'} />
                    <UiStack
                      flexGrow={1}
                      borderRadius={uiStyles.borderRadius}
                      // borderWidth={'1px'}
                      // borderStyle={'solid'}
                      // borderColor={fieldType === 'sectionBreak' ? 'primary.500' : 'gray.300'}
                      // bgColor={fieldType === 'sectionBreak' ? 'primary.50' : '#fff'}
                      justifyContent={'space-between'}
                      // pr={4}
                      // py={2}
                      spacing={0}
                    >
                      <UiText color={fieldType === 'sectionBreak' ? 'primary.500' : 'text.secondary'} variant={'body1'}>Label for a new section</UiText>
                      <UiText color={fieldType === 'sectionBreak' ? 'primary.500' : 'text.secondary'} variant={'caption'}>Description here</UiText>
                      <BaseDividerHorizontal height={2} color={fieldType === 'sectionBreak' ? 'primary.500' : 'gray.300'} />
                    </UiStack>
                  </UiHStack>
                  <UiHStack
                    borderRadius={uiStyles.borderRadius}
                    spacing={4}
                    onClick={() => { setFieldType('descriptionBlock'); }}
                    {...uiStyles.hover}
                  >
                    <UiIconCheckBoxCircle value={fieldType === 'descriptionBlock'} />
                    <UiStack
                      flexGrow={1}
                      borderRadius={uiStyles.borderRadius}
                      borderWidth={'1px'}
                      borderStyle={'solid'}
                      borderColor={fieldType === 'descriptionBlock' ? 'primary.500' : 'gray.300'}
                      // bgColor={fieldType === 'sectionBreak' ? 'primary.50' : '#fff'}
                      justifyContent={'space-between'}
                      // pr={4}
                      p={4}
                      spacing={0}
                    >
                      <UiText color={fieldType === 'descriptionBlock' ? 'primary.500' : 'text.secondary'} variant={'body2'} pb={8}>
                        This is the description block
                      </UiText>
                    </UiStack>
                  </UiHStack>
                </UiStack>
              </UiStack>
            )}
            <UiStack
              p={initFieldMetadata === undefined ? 8 : 0}
              pt={initFieldMetadata === undefined ? 4 : 0}
              pr={0}
              flexGrow={1}
              // bgColor={'blackAlpha.50'}
              borderRadius={uiStyles.borderRadius}
            >
              {fieldType === 'input' && (
                <FieldInputForm
                  fieldMetadata={initFieldMetadata as FieldMetadataInput}
                  onSave={onFieldSave}
                />
              )}
              {fieldType === 'select' && (
                <FieldSelectForm
                  fieldMetadata={initFieldMetadata as FieldMetadataSelect}
                  onSave={onFieldSave}
                />
              )}
              {fieldType === 'date' && (
                <FieldDateForm
                  fieldMetadata={initFieldMetadata as FieldMetadataDate}
                  onSave={onFieldSave}
                />
              )}
              {fieldType === 'phone' && (
                <FieldPhoneForm
                  fieldMetadata={initFieldMetadata as FieldMetadataPhone}
                  onSave={onFieldSave}
                />
              )}
              {fieldType === 'sectionBreak' && (
                <FieldSectionBreakForm
                  fieldMetadata={initFieldMetadata as FieldMetadataSectionBreak}
                  onSave={onFieldSave}
                />
              )}
              {fieldType === 'descriptionBlock' && (
                <FieldDescriptionBlockForm
                  fieldMetadata={initFieldMetadata as FieldMetadataDescriptionBlock}
                  onSave={onFieldSave}
                />
              )}
            </UiStack>
          </UiHStack>
        </UiDrawerBody>
        {/* <UiDrawerFooter p={0}>
          <UiStack flexDirection={'row-reverse'} p={8}>
            {isLoading ? (
              <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'gray'}>
                Add...
              </UiButton>
            ) : (
              <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'}>
                Add
              </UiButton>
            )}
          </UiStack>
        </UiDrawerFooter> */}
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default FieldDrawer;
