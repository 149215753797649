import { type FC, useMemo } from 'react';
import ScrollHandler from '@/registration/component/Layout/ScrollHandler';
import {
  UiText,
  uiStyles,
  UiStack,
  UiHStack,
  UiFlex,
  UiIconArrowsClockwise,
  UiIconArrowSquareOut,
  UiBox,
  UiTag,
  UiIconPencilSimple,
  UiSpinner,
  UiIconHourglassHigh,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import PersonalInfoStep from './PersonalInfo';
import AttendeeCategoriesStep from './AttendeeCategories';
import DiscountCodesStep from './DiscountCodes';
import MainEventTicketsStep from './MainEventTickets';
import FunctionTicketsStep from './FunctionTickets';
import TourTicketsStep from './TourTickets';
import WorkshopTicketsStep from './WorkshopTickets';
import AdditionaInfoStep from './AdditionalInfo';
// import GroupRegistrationStep from './GroupRegistration';
import PaymentMethods from './PaymentMethods';
import OtherSettings from './OtherSettings';
import Privacy from './Privacy';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { RegistrationMode } from '@/api/constant';
import { generateRegisterAbsoluteUrl } from '@/lib/util';
import CloneEvent from './CloneEvent';
import { useBuildEventStore } from '@/registration/hook/useBuildEventStore';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import BasePlainIcon from '@/base/Button/PlainIcon';
import BaseAclContainer from '@/account/component/AclContainer';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseLoadingSpinner from '@/base/Loading/Spinner';
import AutomatedEmail from './AutomatedEmail';
import CampaignsEmail from './CampaignsEmail';
import ScheduledEmail from './ScheduledEmail';
import { useLocalStorage } from '@uidotdev/usehooks';
// import Filter from './Filter';

// export interface StepListItem {
//   description: string;
//   value: string;
//   url: string;
// }

// const getStepsList = (tenantCode: string, eventId: string): StepListItem[] => {
//   return [
//     {
//       description: 'Choose the event',
//       value: 'eventInfo',
//       url: generatePageUrl('RegistrationBuildEventEventInfo', {eventId}),
//     },
//     {
//       description: 'Build delegate basic info form',
//       value: 'personalInfo',
//       url: generatePageUrl('RegistrationBuildEventPersonalInfoForm', {eventId}),
//     },
//     {
//       description: 'Add tickets',
//       value: 'tickets',
//       url: generatePageUrl('RegistrationBuildEventMainEventTickets', {eventId}),
//     },
//     {
//       description: 'Design badge',
//       value: 'badge',
//       url: generatePageUrl('RegistrationBuildEventBadge', {eventId}),
//     },
//   ];
// };

type StepName = 'groupRegistration' | 'eventInfo' | 'attendeeTypes' | 'discountCodes' | 'personalInfo' | 'mainEventTickets' | 'functionTickets' | 'workshopTickets' | 'tourTickets' | 'merchandise' | 'additionalInfo' | 'privacy' | 'paymentMethods' | 'badges' | 'labelsDescriptions' | 'automatedEmail' | 'campaignsEmail' | 'scheduledEmail' | 'otherSettings';

export interface StepsProps {
  activeStep: StepName
  eventId: string
}

const Steps: FC<StepsProps> = ({
  activeStep,
  eventId
}) => {
  // const { clientWidth } = useWindowSize();
  const [enabledEmailFeatureFlag] = useLocalStorage('EMAIL_FEATURE_FLAG', false);
  const { tenantCode, tenant } = useRegisterRoute();
  const attendeeRegisterLink = useMemo(
    () => { return `/event/${tenantCode}/register/${eventId}/email`; },
    [eventId, tenantCode]
  );
  const eventQuery = useEventQuery(eventId);
  const isCloneAvailable = useBuildEventStore((state) => { return state.isCloneAvailable; });

  return (
    <>
      {/*<ScrollHandler />*/}
      <QueryContainer
        query={eventQuery}
        loadingComponent={(
          <UiHStack p={8} justifyContent={'flex-start'}>
            <BaseLoadingSpinner />
          </UiHStack>
        )}
      >
        {(event) => {
          return (
            <UiStack alignItems={'stretch'} spacing={0}>
              <UiStack spacing={0} p={8}>
                <UiHStack
                  spacing={4}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                // bgColor={'#fff'}
                // borderRadius={uiStyles.borderRadius}
                // p={8}
                // py={6}
                >
                  <UiHStack spacing={16}>
                    <UiStack>
                      <UiHStack>
                        <UiText color={'text.secondary'}>Event</UiText>
                        <BaseRouterLink to={generatePageUrl('RegistrationManageEvent', { tenantCode })}>
                          <UiHStack {...uiStyles.hover}>
                            <UiIconArrowsClockwise color="primary.500" size={'2xl'} />
                            <UiText color="primary.500">Change event</UiText>
                          </UiHStack>
                        </BaseRouterLink>
                      </UiHStack>
                      <UiText variant={'body1'}>{event.name}</UiText>
                    </UiStack>
                    <UiStack>
                      <UiHStack>
                        <UiText color={'text.secondary'}>Registration mode</UiText>
                        {/*<BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>*/}
                        {/*  {event.built && event.eventSettings && (*/}
                        {/*    <UiStack {...uiStyles.hover}>*/}
                        {/*      <BaseRouterLink to={generatePageUrl('RegistrationBuildEventOtherSettings', { tenantCode, eventId })+ "#auto-scroll"}>*/}
                        {/*        <BasePlainIcon*/}
                        {/*          Icon={UiIconPencilSimple}*/}
                        {/*          label={'Change'}*/}
                        {/*        />*/}
                        {/*      </BaseRouterLink>*/}
                        {/*    </UiStack>*/}
                        {/*  )}*/}
                        {/*</BaseAclContainer>*/}
                      </UiHStack>
                      <UiBox>
                        {event.registrationMode ? (
                          <UiTag colorScheme={event.registrationMode === RegistrationMode.Live ? 'green' : 'yellow'}>{event.registrationMode}</UiTag>
                        ) : (
                          <BaseLoadingSpinner />
                        )}
                      </UiBox>
                    </UiStack>
                    <UiStack>
                      <UiHStack>
                        <UiHStack>
                          <UiText flexGrow={1} color={'text.secondary'}>Event registration link</UiText>
                          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
                            <BaseRouterLink to={generateRegisterAbsoluteUrl(attendeeRegisterLink) ?? undefined} target={'_blank'}>
                              <UiIconArrowSquareOut color="primary.500" size={'2xl'} />
                            </BaseRouterLink>
                          </BaseAclContainer>
                        </UiHStack>
                      </UiHStack>
                      <UiText variant={'body1'}>{generateRegisterAbsoluteUrl(attendeeRegisterLink)}</UiText>
                    </UiStack>
                  </UiHStack>
                </UiHStack>
              </UiStack>
              <BaseDividerHorizontal height={0} widthOffset={8} />
              {
                !event.built ? (
                  <UiStack p={8}>
                    <UiHStack bgColor={'#fff'} px={8} py={6} borderRadius={uiStyles.borderRadius} justifyContent={'flex-start'} spacing={2}>
                      <UiIconHourglassHigh size={'3xl'} color={'green.500'} />
                      <UiText>
                        The event building is currently in progress. It will take a few minutes. Please come back and check later.
                      </UiText>
                    </UiHStack>
                  </UiStack>
                ) : (!event?.eventSettings && isCloneAvailable) || event?.cloneStatus === 'in_progress' ? (
                  <CloneEvent
                    eventId={eventId}
                    tenantCode={tenantCode}
                  />
                ) : (
                  <UiFlex
                    flexGrow={1}
                    flexWrap={'wrap'}
                    px={4}
                    py={4}
                  >
                    {/* <UiStack p={4}> */}
                    {/*  <GroupRegistrationStep isActive={activeStep === 'groupRegistration'} /> */}
                    {/* </UiStack> */}
                    <UiStack p={4}>
                      <AttendeeCategoriesStep isActive={activeStep === 'attendeeTypes'} />
                    </UiStack>
                    <UiStack p={4}>
                      <DiscountCodesStep isActive={activeStep === 'discountCodes'} />
                    </UiStack>
                    <UiStack p={4}>
                      <Privacy isActive={activeStep === 'privacy'} />
                    </UiStack>
                    <UiStack p={4}>
                      <PersonalInfoStep isActive={activeStep === 'personalInfo'} />
                    </UiStack>
                    <UiStack p={4}>
                      <MainEventTicketsStep isActive={activeStep === 'mainEventTickets'} />
                    </UiStack>
                    <UiStack p={4}>
                      <FunctionTicketsStep isActive={activeStep === 'functionTickets'} />
                    </UiStack>
                    <UiStack p={4}>
                      <WorkshopTicketsStep isActive={activeStep === 'workshopTickets'} />
                    </UiStack>
                    <UiStack p={4}>
                      <TourTicketsStep isActive={activeStep === 'tourTickets'} />
                    </UiStack>
                    <UiStack p={4}>
                      <AdditionaInfoStep isActive={activeStep === 'additionalInfo'} />
                    </UiStack>
                    <UiStack p={4}>
                      <PaymentMethods isActive={activeStep === 'paymentMethods'} />
                    </UiStack>
                    {/* TODO: Uncomment when the feature is ready */}
                    {enabledEmailFeatureFlag && (
                      <>
                        <UiStack p={4}>
                          <AutomatedEmail isActive={activeStep === 'automatedEmail'} />
                        </UiStack>
                        {/*<UiStack p={4}>*/}
                        {/*  <CampaignsEmail isActive={activeStep === 'campaignsEmail'} />*/}
                        {/*</UiStack>*/}
                        {/*<UiStack p={4}>*/}
                        {/*  <ScheduledEmail isActive={activeStep === 'scheduledEmail'} />*/}
                        {/*</UiStack>*/}
                      </>
                    )}
                    {/* <UiStack p={4}>
                  <BadgesStep isActive={activeStep === 'badges'} />
                </UiStack> */}
                    {/* <UiStack p={4}> */}
                    {/*  <LabelsDescriptions isActive={activeStep === 'labelsDescriptions'} /> */}
                    {/* </UiStack> */}
                    <UiStack p={4}>
                      <OtherSettings isActive={activeStep === 'otherSettings'} />
                    </UiStack>
                  </UiFlex>
                )}
            </UiStack>
          );
        }}
      </QueryContainer>
    </>

  );

  // const listItems = useMemo(
  //   () => {
  //     return getStepsList(tenantCode, eventId);
  //   },
  //   [tenantCode, eventId]
  // );

  // return (
  //   <UiHStack spacing={8} flexGrow={1}>
  //     {listItems.map((listItem) => {
  //       if (activeStep === listItem.value) {
  //         return (
  //           <UiVStack
  //             alignItems={'flex-start'}
  //             spacing={0}
  //             minW={260}
  //             maxW={260}
  //             minH={260}
  //             maxH={260}
  //             bgColor={'primary.50'}
  //             p={4}
  //             // px={8}
  //             borderRadius={uiStyles.borderRadius}
  //           >
  //             <UiStack flexGrow={1}></UiStack>
  //             {/* <UiText variant={'title'}>{listItem.title}</UiText> */}
  //             <UiText variant={'body1'} fontWeight={600}>{listItem.description}</UiText>
  //           </UiVStack>
  //         );
  //       } else {
  //         return (
  //           <BaseRouterLink to={listItem.url}>
  //             <UiVStack
  //               minW={260}
  //               maxW={260}
  //               minH={260}
  //               maxH={260}
  //               alignItems={'flex-start'}
  //               {...uiStyles.hover}
  //               spacing={0}
  //               borderRadius={uiStyles.borderRadius}
  //               p={4}
  //               // px={8}
  //               bgColor={'#fff'}
  //               // borderWidth={'1px'}
  //               // borderStyle={'solid'}
  //               // borderColor={'gray.300'}
  //             >
  //               <UiStack flexGrow={1}></UiStack>
  //               {/* <UiText variant={'title'}>{listItem.title}</UiText> */}
  //               <UiText variant={'body1'} fontWeight={600}>{listItem.description}</UiText>
  //             </UiVStack>
  //           </BaseRouterLink>
  //         );
  //       }
  //     })}
  //   </UiHStack>
  // );
};

export default Steps;
