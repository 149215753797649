import { type MergeTags, TrixEditor } from 'react-trix';
import { ChakraProvider } from '@chakra-ui/react';

import 'trix/dist/trix';
import 'trix/dist/trix.css';
import { UiStack, UiText } from '@/lib/ui';
import FieldContainer from '@/base/Form/FieldContainer';
import { forwardRef } from 'react';

export interface ITrixEditor extends TrixEditor {
  editor: {
    loadHTML: (html: string) => void
    reset: () => void
  }
}

interface ReactTrixProps {
  handleChange: (html: string, text: string) => void
  ref: React.RefObject<ITrixEditor>
}

export const ReactTrix = forwardRef<TrixEditor, ReactTrixProps>((props, ref) => {
  const { handleChange } = props;
  const mergeTags: MergeTags[] = [];

  return (
    <ChakraProvider>
      <FieldContainer layout='inline'>
        <UiText color={'gray.600'} gridColumn={1}>Content</UiText>
        <UiStack spacing={4} gridColumn={2} alignItems='flex-start' maxWidth={'100%'} wordBreak='break-word'>
          <TrixEditor ref={ref} className='trix-editor-container' onChange={handleChange} mergeTags={mergeTags} />
        </UiStack>
      </FieldContainer>
    </ChakraProvider>
  );
});

ReactTrix.displayName = 'ReactTrix';

export default ReactTrix;
